import dayjs from 'dayjs';
export const setPublicUserData = () => {
  const currentDate = dayjs();
  const { firstLogin, registerDate, protocolFlag } = JSON.parse(
    localStorage.getItem('userInfo') || '{}'
  );
  const userData = localStorage.getItem('userInfo')
    ? {
        is_new_customer: firstLogin !== undefined ? firstLogin : true,
        register_time: registerDate,
        membership_years: currentDate.diff(dayjs(registerDate), 'month'),
        membership_days: currentDate.diff(dayjs(registerDate), 'day'),
        is_crm_member: protocolFlag || false
      }
    : {};
  const presetProperties = JSON.parse(localStorage.getItem('sensorsPublicData') || '{}');
  const payload = {
    ...presetProperties,
    ...userData
  };
  // eslint-disable-next-line camelcase
  localStorage.setItem('sensorsPublicData', JSON.stringify(payload));
};
