import VConsole from 'vconsole';

// 判断是否是pc设备
const isPc = () => {
  const userAgentInfo = navigator && navigator.userAgent ? navigator.userAgent : '';
  const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
};

const vConsole = process.env.NODE_ENV === 'development' && !isPc() ? new VConsole() : '';
// 打开vconsole 调试
// const vConsole = new VConsole();
export default vConsole;
