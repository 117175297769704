import { useAccount } from '@speedshop/sdk-api';
import Cookies from 'js-cookie';
import { logout } from '@/graphql/login.gql';
import { GuestLogin } from '@/utils/guestLogin.ts';

// 在当前页面过期需跳转首页页
const REDIRECT_LOGIN = [
  'Account',
  'PersonalCenterIndex',
  'accountSetting',
  'accountSettingLoginInfo',
  'accountSettingLogoutEmail',
  'accountSettingPersonInfo',
  'accountSettingLogout',
  'address',
  'address-old',
  'Order',
  'orderDetails',
  'refundList',
  'ReturnDetails',
  'coupons',
  'wishlist',
  'memberRights',
  'checkout',
  'paySuccess',
  'payAgain',
  'pay-transfer',
  'OrderReturnPage',
  'OrderExchangePage',
  'shopCart'
];

export default class Logout {
  constructor(arg) {
    const { app = null, store = null, currentRouteName = '' } = arg || {};
    this.accountInstance = useAccount();
    this.app = app;
    this.store = store;
    this.currentRouteName = currentRouteName;
  }

  async init() {
    // localStorage clear
    this.clearLocalStorage();
    // cookie clear
    this.clearAllCookie();

    if (this.store) {
      // 设置为游客
      this.store.commit('setGuest', true);
      this.store.commit('setMiniCartNum', 0);
      this.store.commit('setIsLogin', false);
    }

    // 接口 logout
    await this.handleLogoutAjax();

    if (this.app) {
      // 埋点
      this.app.$sensors.logout();
      this.guestInit(this.app);

      this.goLoginPage(this.currentRouteName);
    }
  }

  clearLocalStorage() {
    localStorage?.clear();
  }

  clearAllCookie() {
    Cookies.remove('token');
    Cookies.remove('AUTH-TOKEN');
    localStorage.removeItem('token');
    localStorage.removeItem('AUTH-TOKEN');
    localStorage.removeItem('userInfo');
    sessionStorage.clear();
  }

  goLoginPage(name, isServer = false) {
    if (!isServer) {
      if (REDIRECT_LOGIN.includes(name)) {
        window.location.href = '/';
      } else {
        location.reload();
      }
    } else if (REDIRECT_LOGIN.includes(name) || !name) {
      redirect('/');
    }
  }

  async handleLogoutAjax() {
    try {
      const {
        data: { api }
      } = await this.accountInstance.gqlClient.query(logout);
      return api;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async guestInit(apply) {
    if (
      (localStorage &&
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo')).isVisitor) ||
      localStorage.getItem('token')
    ) {
      return;
    }
    GuestLogin && (await GuestLogin(app));
  }
}
