import Vue from 'vue';
import { useBasic } from '@speedshop/sdk-api';
import { queryCustomerServiceLink } from '@/graphql/common.gql';
import { mockWindowOpen } from '@/utils/tools';

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
class CustomerService {
  public productId;
  public productName;

  injectProduct(productId, productName) {
    this.productId = productId;
    this.productName = productName;
  }

  async getCustomerServiceLink(productId, productName) {
    const basic = useBasic();
    const data = await basic.gqlClient.query(queryCustomerServiceLink, {
      input: {
        enterurl: location.href,
        productId,
        productName
      }
    });
    console.log('Customer Service: get Link', data.data.data.hyperlink);
    return data.data.data.hyperlink;
  }

  async open() {
    const link = await this.getCustomerServiceLink(this.productId, this.productName);
    if (isMobile()) {
      location.href = link;
    } else {
      mockWindowOpen(link, '_blank');
    }
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $customerService: InstanceType<typeof CustomerService>;
  }
}
function formatComponentName(vm) {
  if (vm.$root === vm) return 'root';
  const name = vm._isVue
    ? (vm.$options && vm.$options.name) || (vm.$options && vm.$options._componentTag)
    : vm.name;
  return (
    (name ? 'component <' + name + '>' : 'anonymous component') +
    (vm._isVue && vm.$options && vm.$options.__file
      ? ' at ' + (vm.$options && vm.$options.__file)
      : '')
  );
}

const service = {
  install(Vue) {
    Vue.config.errorHandler = function (err, vm, info) {
      if (!window.TINGYUN) {
        return;
      }
      if (vm) {
        const componentName = formatComponentName(vm);
        const propsData = vm.$options && vm.$options.propsData;
        window.TINGYUN.captureException(err, {
          componentName,
          propsData,
          info
        });
      } else {
        window.TINGYUN.captureException(err);
      }
    };
    Vue.prototype.$customerService = new CustomerService();
  }
};

Vue.use(service);

export default nuxt => {
  const { store, app } = nuxt;
  // console.log('Customer Service: nuxt', nuxt);
  // console.log('Customer Service: app', app);
  // console.log('Customer Service: store', store);
  // console.log('Customer Service: state.userInfo', store.state.accountInfo);
  // // console.log('Customer Service: basic', basic)

  app.router.beforeEach((to, from, next) => {
    Vue.prototype.$customerService = new CustomerService();
    // console.log('Customer Service: route.beforeEach');
    // console.log('Customer Service: --->to', to);
    // console.log('Customer Service: --->from', from);
    next();
  });
};
