export const SaleAttributeCodeMapData = {
  COLOR_CODE: 'color',
  SIZE_CODE: 'SIZE'
};

export const VariousCategoryCode = 'CA230717013058';

export const MemberLevel = [
  [
    {
      name: '欢迎礼券',
      icon: 'icon-a-24-1px-youhui1'
    },
    {
      name: '课程活动',
      icon: 'icon-a-24-2px-zhufuclass'
    },
    {
      name: '生日礼券',
      icon: 'icon-a-24-2px-zhufu'
    },
    {
      name: '解锁形象',
      icon: 'icon-a-24-1px-member'
    }
  ],
  [
    {
      name: '专享优惠',
      icon: 'icon-a-24-1px-cuxiao-2'
    },
    {
      name: '积分奖励',
      icon: 'icon-a-24-1px-jifen-2'
    },
    {
      name: '新品优先',
      icon: 'icon-a-24-1px-shop-bag'
    },
    {
      name: '限定包装',
      icon: 'icon-a-24-1px-daifahuo'
    },
    {
      name: '形象升级',
      icon: 'icon-a-24-1px-member-L2'
    }
  ],
  [
    {
      name: '积分奖励',
      icon: 'icon-a-24-1px-jifen-3'
    },
    {
      name: '免费洗鞋',
      icon: 'icon-a-24-1px-shoes'
    },
    {
      name: '早鸟购',
      icon: 'icon-a-24-2px-couponfirst'
    },
    {
      name: '生日尊享',
      icon: 'icon-a-24-1px-birthday'
    },
    {
      name: '形象升级',
      icon: 'icon-a-24-1px-member-L3'
    }
  ],
  [
    {
      name: '专享优惠',
      icon: 'icon-a-24-1px-cuxiao-4'
    },
    {
      name: '积分奖励',
      icon: 'icon-a-24-1px-jifen-4'
    },
    {
      name: '运动体验',
      icon: 'icon-a-24-1px-sporter'
    },
    {
      name: '独家兑换',
      icon: 'icon-a-24-1px-jifen-4'
    },
    {
      name: '形象升级',
      icon: 'icon-a-24-1px-member-L4'
    }
  ],
  [
    {
      name: '积分奖励',
      icon: 'icon-a-24-1px-jifen-5'
    },
    {
      name: '活动体验',
      icon: 'icon-a-24-1px-tequan'
    },
    {
      name: '年度礼遇',
      icon: 'icon-a-24-1px-gift'
    },
    {
      name: '形象升级',
      icon: 'icon-a-24-1px-member-L5'
    }
  ]
];
