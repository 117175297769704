import { GtMessage } from '@speedshop/template';

export const limitTips = {
  inserted(el, { value, modifiers = {} }, vnode, oldVnode) {
    const increase = el.querySelector('.el-input-number__increase');
    const decrease = el.querySelector('.el-input-number__decrease');
    const inputDom = el.querySelector('.el-input__inner');

    if (!increase) return;

    // + 号的逻辑

    increase.addEventListener('click', () => {
      const hasDisabled = !!el.querySelector('.el-input-number__increase.is-disabled');
      const value = el.getAttribute('value');

      if (hasDisabled && el.prevalue === value) {
          GtMessage.error('超出可加购数量');
          vnode.context.$eventBus.$emit('over-limit-click', { modifiers, over: true })
      } else {
        el.prevalue = value;
        vnode.context.$eventBus.$emit('over-limit-click', { modifiers, over: false })
      }
    });

    // - 号的逻辑
    decrease.addEventListener('click', () => {
      const value = el.getAttribute('value');

      el.prevalue = value;
      vnode.context.$eventBus.$emit('over-limit-click', { modifiers, over: false })
    });

    // 其他事件的逻辑
    let inputingValue = '';

    inputDom.addEventListener('input', event => {
      const value = event.target.value;

      inputingValue += value;
    });

    const checkInputValue = max => {
      if (Number(inputingValue) > Number(max)) {
        GtMessage.error('超出可加购数量');
        vnode.context.$eventBus.$emit('over-limit-click', { modifiers, over: true })
      }else {
        vnode.context.$eventBus.$emit('over-limit-click', { modifiers, over: false })
      }

      inputingValue = '';
    };

    inputDom.addEventListener('blur', event => {
      checkInputValue(event.target.max);
    });
    inputDom.addEventListener('keypress', event => {
      checkInputValue(event.target.max);
    });
  }
};
