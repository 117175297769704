/*
 * @Author: xiejun jun.xie@gtechhdigi.com
 * @Date: 2024-08-05 18:59:45
 * @LastEditors: xiejun jun.xie@gtechhdigi.com
 * @LastEditTime: 2024-08-12 10:53:56
 * @FilePath: /ua-cnbs-mall/src/utils/guestLogin.ts
 */
import { useHome } from '@speedshop/sdk-api';
export const GuestLogin = async app => {
  const data = await useHome().guestInit();
  const {
    userAuthToken,
    visitorUniqueKey = '',
    account = '',
    firstName = '',
    lastName = ''
  } = data;
  let userAccount = account || '';
  if (!account) {
    userAccount = `${firstName} ${lastName}`;
  }

  localStorage.setItem('userInfo', JSON.stringify({ ...data, account: userAccount }));

  if (app && app.$store) {
    app.$store.commit('setIsLogin', true);
    app.$store.commit('setGuest', true);
    if (process.server) {
      if (userAuthToken) {
        app.$cookies.remove('token');
        app.$cookies.set('token', userAuthToken);
        app.$cookies.remove('guestId');
      }
      if (visitorUniqueKey) {
        app.$cookies.set('guestId', visitorUniqueKey);
      }
    } else {
      if (userAuthToken) {
        localStorage.setItem('token', userAuthToken);
        app.$cookies.remove('token');
        app.$cookies.set('token', userAuthToken);
      }

      // 设置游客唯一key
      if (visitorUniqueKey) {
        localStorage.setItem('guestId', visitorUniqueKey);
        app.$cookies.remove('guestId');
        app.$cookies.set('guestId', visitorUniqueKey);
      }
    }
  }

  if (visitorUniqueKey) {
    console.log(
      '%c [  ]-55',
      'font-size:13px; background:pink; color:#bf2c9f;',
      visitorUniqueKey,
      window.TINGYUN
    );
    window.TINGYUN && window.TINGYUN.setUid(visitorUniqueKey);
  }
};
