import Vue from 'vue';

export const $Address = function (value) {
  const {
    stateName = '',
    cityName = '',
    districtName = '',
    zipCode = '',
    address1 = ''
  } = value || {};
  return `${stateName}${cityName}${districtName}${address1}`;
};

export const $PriceFloat = function (money) {
  if (!money && money != 0) return money;

  const env = process.env || {
    currency: '¥',
    currencyPosition: 'LEFT',
    toFixed: 2,
    currencySeparator: ','
  };
  const { currency = '¥', currencyPosition = 'LEFT', toFixed = 2, currencySeparator = ',' } = env;

  const fixedNum = Number(toFixed) || 0;
  const sign = currencySeparator;
  // eslint-disable-next-line no-useless-escape
  money = `${parseFloat(`${money || 0}`.replace(/[^\d\.-]/g, '')).toFixed(fixedNum)}`;
  const moneyStart = money
    .split('.')[0]
    // eslint-disable-next-line no-useless-escape
    .toString()
    .replace(/(\d)(?=(?:\d{3})+$)/g, `$1${sign}`);

  const dotValue = Number(money).toFixed(fixedNum).split('.')[1];
  let computedMoney = '0';
  // id
  if (sign === '.') {
    computedMoney = Number(dotValue) > 0 ? `${moneyStart},${dotValue}` : moneyStart;
  }
  // other
  if (sign === ',') {
    computedMoney = Number(dotValue) > 0 ? `${moneyStart}.${dotValue}` : moneyStart;
  }

  if (!dotValue) {
    computedMoney = moneyStart;
  }

  return currencyPosition === 'RIGHT'
    ? `${computedMoney} ${currency}`
    : `${currency} ${computedMoney}`;
};

Vue.filter('$Address', $Address);
Vue.filter('$PriceFloat', $PriceFloat);
