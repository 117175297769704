import type { PluginObject, DirectiveOptions } from 'vue';
import VueRouter from 'vue-router';
import cookie from 'js-cookie';
import type { Route } from 'vue-router';
import userActiveListener from './user-active-listener';
import type { ObserveOption } from './user-active-listener';

const trackTypes = ['expose', 'click', 'hover'];
type TrackType = typeof trackTypes[number];
let usePrevious = false;
let currentPageData: Record<string, any>;
let extraCacheDataHandle;

let commonTrackHandle = (s: InstanceType<typeof TrackObject>) => {
  console.log(`track-help: ${s.type}-${s.eventName} track`, s);
};

const KEY_PREVIOUS_PAGE_DATA = 'TrackHelp/PreviousPageData';

/**
 * 将暂存数据，持久化到sessionStorage。//Fansiyao 变更为localStorage, 变更原因商品详情页需要用新标签打开
 * 当导航变化的时候触发，记录以'当前页面-目标页面'为key，
 * 在目标页面通过‘前向页面-当前页面’可以锁定这个key，
 *
 * @param to 导航守卫中的to
 * @param from 导航守卫中的from
 * @returns {void}
 */
const cachePageData = (path, data, to, from) => {
  if (!data) return;
  const pageData = localStorage.getItem(KEY_PREVIOUS_PAGE_DATA);
  const session = pageData ? JSON.parse(pageData) : undefined;
  const parseData = extraCacheDataHandle?.(data, to, from) || data;
  const previousPageData = { ...session, [path]: parseData };
  console.log('track-help: cachePageData', path, parseData);
  localStorage.setItem(KEY_PREVIOUS_PAGE_DATA, JSON.stringify(previousPageData));
};

/**
 * 暂存数据，刷新丢失
 * @param obj
 */
const setPageData = obj => {
  currentPageData = { ...currentPageData, ...obj };
  console.log('track-help: setPageData', obj);
};

/**
 * 获取前向页面数据
 * @returns {Record<string, any>}
 */
const getPreviousPageData = () => {
  const data = localStorage.getItem(KEY_PREVIOUS_PAGE_DATA);
  if (!data) return;
  const previousPageData = JSON.parse(data);
  const path = `${location.pathname}${location.search}`;
  const currentPage = previousPageData[path];
  console.log(`track-help: getPreviousPageData`, currentPage);
  return currentPage || {};
};

export class TrackObject {
  el: Element;
  type: TrackType;
  eventName: string;
  data: Record<string, any>;
  isCache: boolean;
  extraCacheData: Record<string, any>;
  once: boolean;
  isBind: boolean;
  beforeTrack: (a: Record<string, any>) => Promise<Record<string, any>> | Record<string, any>;
  trackHandle: (s: InstanceType<typeof TrackObject>) => Promise<void> | void;
  afterTrack: (a: Record<string, any>) => Promise<void> | void;
  registerPage: any;
  constructor(options: Partial<TrackObject>) {
    const {
      el,
      type,
      eventName,
      data,
      isCache = false,
      extraCacheData,
      once = false,
      beforeTrack,
      afterTrack,
      trackHandle = commonTrackHandle,
      registerPage
    } = options;
    this.el = el;
    this.type = type;
    this.eventName = eventName;
    this.data = data;
    this.trackHandle = trackHandle;
    this.beforeTrack = beforeTrack;
    this.afterTrack = afterTrack;
    this.once = once;
    this.isCache = isCache;
    this.extraCacheData = extraCacheData;
    this.registerPage = registerPage;
  }

  updateData(data) {
    this.data = data;
  }

  updateCache(data) {
    this.extraCacheData = data;
  }

  handle = async () => {
    this.beforeTrack && (await this.beforeTrack(this));
    console.log('track-help: handle', this);
    if (usePrevious) {
      if (this.isCache) {
        // 将当前data暂存
        setPageData(this.data);
      }
      if (this.extraCacheData) {
        // 将附加的数据暂存
        setPageData(this.extraCacheData);
      }
      const previousPageData = getPreviousPageData();
      if (previousPageData) {
        // 如果存在前向页面数据，合并到data中;
        this.updateData({ ...previousPageData, ...this.data });
      }
    }
    if (this.registerPage) {
      const presetProperties = JSON.parse(localStorage.getItem('sensorsPublicData') || '{}');
      const cookieData = JSON.parse(cookie.get('sensors_publick_properties') || '{}');
      const payload = {
        ...cookieData,
        ...presetProperties,
        ...this.registerPage
      };
      // 从公共属性中去除动态属性，不需要存储，以实际上报为准
      delete payload.module_name;
      delete payload.module_rank;
      delete payload.operation_id;
      delete payload.operation_name;
      delete payload.operation_rank;
      localStorage.setItem('sensorsPublicData', JSON.stringify(payload));
      cookie.set('sensors_publick_properties', JSON.stringify(payload), {
        path: '/',
        ...(process.env.NODE_ENV === 'production'
          ? {
              sameSite: 'None',
              domain: '.' + location.host.split('.').slice(-2).join('.'),
              secure: true
            }
          : {})
      });
    }
    await this.trackHandle(this);
    if (this.once) {
      this.unbindEvent();
    }
    this.afterTrack && (await this.afterTrack(this));
  };

  bindEvent() {
    this.isBind = true;
    switch (this.type) {
      case 'expose': {
        intersectionObserver.observe(this.el);
        break;
      }
      case 'click': {
        this.el.addEventListener('click', this.handle);
        break;
      }
      case 'hover': {
        this.el.addEventListener('mouseenter', this.handle);
        break;
      }
      default:
        break;
    }
  }

  unbindEvent() {
    this.isBind = false;
    switch (this.type) {
      case 'expose': {
        intersectionObserver.unobserve(this.el);
        break;
      }
      case 'click': {
        this.el.removeEventListener('click', this.handle);
        break;
      }
      case 'hover': {
        this.el.removeEventListener('mouseenter', this.handle);
        break;
      }
      default:
        break;
    }
  }
}

const TrackMap: Record<TrackType, Map<Element, TrackObject>> = trackTypes.reduce(
  (res, item) => ({ ...res, [item]: new Map<Element, TrackObject>() }),
  {}
);
const intersectionObserver = new IntersectionObserver(entries => {
  entries.forEach(item => {
    const IOKey = trackTypes[0];
    const trackObj = TrackMap[IOKey].get(item.target);
    if (item.intersectionRatio > 0) {
      trackObj.handle();
    }
  });
});

const parseBinding = binding => {
  const { arg, value, modifiers } = binding;
  const params: Record<string, any> = { ...modifiers };
  if (typeof value === 'function') {
    params.trackHandle = value;
  } else if (value.eventName && value.data) {
    return value;
  } else {
    params.eventName = arg;
    params.data = value;
  }
  return params;
};

/**
 * @description: 使用IntersectionObserver实现的监听指令，只绑定一次，首次触发后即解除监听。
 * @return {DirectiveOptions}
 */
export const IOTrack: DirectiveOptions = {
  bind(el: Element, binding) {
    const IOKey = trackTypes[0];
    const options = parseBinding(binding);
    const trackObj = new TrackObject({ el, type: IOKey, once: true, ...options });
    trackObj.bindEvent();
    TrackMap[IOKey].set(el, trackObj);
  },
  update(el: Element, binding) {
    const IOKey = trackTypes[0];
    const options = parseBinding(binding);
    const trackObj = TrackMap[IOKey].get(el);
    trackObj.updateData(options.data);
    trackObj.updateCache(options.extraCacheData);
  },
  unbind(el: Element) {
    const IOKey = trackTypes[0];
    const trackObj = TrackMap[IOKey].get(el);
    trackObj.unbindEvent();
    TrackMap[IOKey].delete(el);
  }
};

/**
 * @description: 使用IntersectionObserver实现的监听指令，首次触发后即解除监听。
 * @return {DirectiveOptions}
 */
export const ExposeTrack: DirectiveOptions = {
  bind(el: Element, binding) {
    const IOKey = trackTypes[0];
    const options = parseBinding(binding);
    const trackObj = new TrackObject({ el, type: IOKey, ...options });
    trackObj.bindEvent();
    TrackMap[IOKey].set(el, trackObj);
  },
  update(el: Element, binding) {
    const IOKey = trackTypes[0];
    const options = parseBinding(binding);
    const trackObj = TrackMap[IOKey].get(el);
    trackObj.updateData(options.data);
    trackObj.updateCache(options.extraCacheData);
  },
  unbind(el: Element) {
    const IOKey = trackTypes[0];
    const trackObj = TrackMap[IOKey].get(el);
    trackObj.unbindEvent();
    TrackMap[IOKey].delete(el);
  }
};

/**
 * @description: 点击触发track，可多次触发
 * @return {DirectiveOptions}
 */
export const clickTrack: DirectiveOptions = {
  bind(el: Element, binding) {
    const IOKey = trackTypes[1];
    const options = parseBinding(binding);
    const trackObj = new TrackObject({ el, type: IOKey, ...options });
    trackObj.bindEvent();
    TrackMap[IOKey].set(el, trackObj);
  },
  update(el: Element, binding) {
    const IOKey = trackTypes[1];
    const options = parseBinding(binding);
    const trackObj = TrackMap[IOKey].get(el);
    trackObj.updateData(options.data);
    trackObj.updateCache(options.extraCacheData);
  },
  unbind(el: Element) {
    const IOKey = trackTypes[1];
    const trackObj = TrackMap[IOKey].get(el);
    trackObj.unbindEvent();
    TrackMap[IOKey].delete(el);
  }
};

/**
 * @description: 悬浮触发track，可多次触发
 * @return {DirectiveOptions}
 */
export const hoverTrack: DirectiveOptions = {
  bind(el: Element, binding) {
    const IOKey = trackTypes[2];
    const options = parseBinding(binding);
    const trackObj = new TrackObject({ el, type: IOKey, ...options });
    trackObj.bindEvent();
    TrackMap[IOKey].set(el, trackObj);
  },
  update(el: Element, binding) {
    const IOKey = trackTypes[2];
    const options = parseBinding(binding);
    const trackObj = TrackMap[IOKey].get(el);
    trackObj.updateData(options.data);
    trackObj.updateCache(options.extraCacheData);
  },
  unbind(el: Element) {
    const IOKey = trackTypes[2];
    const trackObj = TrackMap[IOKey].get(el);
    trackObj.unbindEvent();
    TrackMap[IOKey].delete(el);
  }
};

/**
 * 指令v-IO-track, v-expose-track, v-click-track, v-hover-track
 * 方法一: 传入参数对象，如: :v-expose-track:exposeView="{ ... }" 将直接触发trackHandle
 * 方法二: 传入方法 :v-expose-track="myTrackHandle", 即执行自定义的方法
 * 方法三: 传入指令支持的options对象, 如:
 * :v-expose-track="{
 *   eventName: exposeView,
 *   data: { ... },
 *   trackHandle: myTrackHandle,
 *   beforeTrack： mybeforeTrack,
 *   afterTrack: myafterTrack
 * }"
 * 支持修饰符.once，作用是事件只触发一次即解绑。
 *
 * TrackHelp在开启usePreviousCache后，router必传。且注册全局对象$trackHelp。
 * $trackHelp.setPageData(obj): 暂存数据
 * $trackHelp.getPreviousPageData(): 获取前置数据
 *
 * $previousPage, v-IO-track将被废弃，目前依旧可以用。
 *
 */

const commonTrack = async (eventName: string, data: Record<string, any>) => {
  const trackObj = new TrackObject({ eventName, data });
  await trackObj.handle();
};

type Utils = {
  setPageData?: typeof setPageData;
  getPreviousPageData?: typeof getPreviousPageData;
  track: typeof commonTrack;
  cachePageData?: typeof cachePageData;
  setUserActiveData?: (key: string, updateHandle: (d: any) => any) => void;
};

export type TrackHelpPluginOptions = {
  trackHandle?: (s: InstanceType<typeof TrackObject>, d: Utils) => Promise<void> | void;
  cacheDataHandle?: (s: Record<string, any>) => Record<string, any>;
  afterRouteEnterHandle?: (d: Record<string, any>, t: Route, f: Route) => Record<string, any>;
  usePreviousCache?: boolean;
  router?: InstanceType<typeof VueRouter>;
  observes?: Record<string, ObserveOption>;
  inactiveHandle?: (k: string, d: any) => void;
};

const service: PluginObject<TrackHelpPluginOptions> = {
  install(Vue, options = {}) {
    const {
      trackHandle,
      cacheDataHandle,
      afterRouteEnterHandle,
      usePreviousCache = false,
      router,
      observes,
      inactiveHandle
    } = options;

    const utils: Utils = {
      track: commonTrack
    };
    Vue.prototype.$trackHelp = utils;
    Vue.prototype.$previousPage = utils;
    if (observes) {
      const { updateData } = userActiveListener({ observes, inactiveHandle });
      utils.setUserActiveData = updateData;
    }
    if (usePreviousCache) {
      usePrevious = usePreviousCache;
      extraCacheDataHandle = cacheDataHandle;
      utils.setPageData = setPageData;
      utils.getPreviousPageData = getPreviousPageData;
      utils.cachePageData = cachePageData;
      router.beforeResolve((to, from, next) => {
        // console.log('track-help: previousPage beforeResolve', to, from);
        // 如果存在history.state.key，说明已经生成历史记录，开始缓存当前页面数据
        if (history.state?.key) {
          cachePageData(to.fullPath, currentPageData, to, from);
          currentPageData = {};
        }
        next();
      });
      router.afterEach((to, from) => {
        if (from.name) {
          currentPageData = afterRouteEnterHandle?.(currentPageData, to, from) || currentPageData;
          console.log('track-help: router.afterEach', currentPageData);
        }
      });
      // window.addEventListener('popstate', (event) => {
      //   // event.state 包含历史记录的状态对象
      //   if (event.state) {
      //     console.log('浏览器前进后退操作');
      //     console.log('新的 history.state:', event.state);
      //   }
      // });
    }
    if (trackHandle) {
      commonTrackHandle = data => trackHandle(data, utils);
    }
    Vue.directive('IO-track', IOTrack);
    Vue.directive('expose-track', ExposeTrack);
    Vue.directive('click-track', clickTrack);
    Vue.directive('hover-track', hoverTrack);
  }
};

export default service;
