import Vue from 'vue';
import ShippingBag from '@/components/ShopCart/shippingBagDrawer.vue'

// Golbal Single Instance
let ShippingBagInstance

export default ({
  app,
  store
}, inject) => {
  inject('shippingBag', {
    _initDialog() {    
      
      const Sub = Vue.extend(ShippingBag);

      const vnode = new Sub();
    
      vnode.$mount();
    
      document.body.appendChild(vnode.$el);
    
      return vnode
    },
    
    show: function(data) {
      if (!ShippingBagInstance) {
        ShippingBagInstance = this._initDialog({
          i18n: app.i18n || undefined
        })
      }
      return ShippingBagInstance.show(data)
    },

    close: function() {
      if (!ShippingBagInstance) return;
      ShippingBagInstance.close()
    }
  })
};