<template>
  <div class="laboratory">
    <div class="font-wrapper">
      <div style="font-family: 'PingFangSC' !important; font-weight: 400">
        PingFangSC 1234567890
      </div>
      <div style="font-family: 'PingFangSC' !important; font-weight: 500">
        PingFangSC 1234567890
      </div>
      <div style="font-family: 'PingFangSC' !important; font-weight: 600">
        PingFangSC 1234567890
      </div>
      <div style="font-family: 'PingFangSC' !important; font-weight: 700">
        PingFangSC 1234567890
      </div>
      <div>Neue Plak 1234567890</div>
      <div style="font-family: 'Neue Plak' !important; font-weight: 400">Neue Plak 400</div>
      <div style="font-family: 'Neue Plak' !important; font-weight: 500">Neue Plak 500</div>
      <div style="font-family: 'Neue Plak' !important; font-weight: 600">Neue Plak 600</div>
      <div style="font-family: 'Neue Plak' !important; font-weight: 700">Neue Plak 700</div>
      <div style="font-family: 'Neue Plak' !important; font-weight: blod">Neue Plak blod</div>
      <div style="font-family: 'Neue Plak Black' !important; font-weight: 700">
        Neue Plak Bold 700
      </div>
      <div style="font-family: 'Neue Plak Regular' !important; font-weight: 400">
        Neue Plak Regular 400
      </div>
      <div style="font-family: 'Neue Plak UA Regular' !important; font-weight: 400">
        Neue Plak UA Regular 400
      </div>
    </div>
    <div>
      <div style="height: 100px"></div>
      <CouponSelect
        v-model="couponSelection"
        :coupon-list="couponList1"
        :disabled-coupon-list="couponList"
      ></CouponSelect>
    </div>
    <div class="coupon-wrapper">
      <template v-for="(coupon, index) in couponList1">
        <CouponOption
          :key="index"
          :check="selection.includes(coupon.couponCode)"
          :coupon-data="coupon"
          :disabled="!coupon.isReward"
          @click="onCouponOptionClick(coupon)"
          @showRule="onShowRule(coupon)"
        ></CouponOption>
      </template>
    </div>
    <div class="coupon-wrapper">
      <template v-for="(coupon, index) in couponList1">
        <CouponItem
          :key="index"
          :check="selection.includes(coupon.couponCode)"
          :coupon-data="coupon"
          :disabled="!coupon.isReward"
          :rule="false"
          @click="onCouponOptionClick(coupon)"
          @showRule="onShowRule(coupon)"
        ></CouponItem>
      </template>
    </div>
    <div class="coupon-wrapper">
      <template v-for="coupon in couponList">
        <CouponItem
          :key="coupon.couponCode"
          :coupon-data="coupon"
          :disabled="!coupon.isReward"
          @click="onCouponOptionClick(coupon)"
          @showRule="onShowRule(coupon)"
        ></CouponItem>
      </template>
    </div>
    <CouponRulePopup ref="couponRulePopupRef"></CouponRulePopup>
  </div>
</template>

<script>
import { useBasic } from '@speedshop/sdk-api';
import CouponOption from '../components/Coupons/CouponOption.vue';
import CouponItem from '../components/Coupons/CouponItem.vue';
import CouponRulePopup from '../components/Coupons/CouponRulePopup.vue';
import CouponSelect from '../components/Coupons/CouponSelect.vue';
import { uaCartQueryCouponsList } from '@/graphql/coupon.gql';

export default {
  name: 'Laboratory',
  components: {
    CouponOption,
    CouponRulePopup,
    CouponSelect,
    CouponItem,
  },
  data() {
    return {
      couponSelection: [],
      couponList1: [
        {
          couponCode: '01',
          activityName: '满1000减999',
          rewardType: '01',
          memberLevelDsc: 'L2+会员专享',
          validEndTime: '2024-12-31 06:25:49',
          validStartTime: '2024-12-31 06:25:49',
          faceValue: '1000',
          isReward: true
        },
        {
          couponCode: '02',
          activityName: '满1000减999',
          rewardType: '02',
          memberLevelDsc: '',
          validEndTime: '2024-12-31 06:25:49',
          validStartTime: '2024-12-31 06:25:49',
          faceValue: '95',
          isReward: true
        },
        {
          couponCode: '03',
          activityName: '满1000减999',
          rewardType: '05',
          memberLevelDsc: '',
          validEndTime: '2024-12-31 06:25:49',
          validStartTime: '2024-12-31 06:25:49',
          faceValue: '1000',
          isReward: true
        },
        {
          couponCode: '04',
          activityName: '满1000减999',
          rewardType: '06',
          memberLevelDsc: '',
          activityShortDesc: '打撒是大幅度',
          validEndTime: '2024-12-31 06:25:49',
          validStartTime: '2024-12-31 06:25:49',
          faceValue: '1000',
          isReward: true
        },
        {
          couponCode: '05',
          activityName: '满1000减999',
          rewardType: '07',
          memberLevelDsc: '',
          activityShortDesc: '打撒是大幅度所发生的法师打发手打发达说法是打发手打',
          validEndTime: '2024-12-31 06:25:49',
          validStartTime: '2024-12-31 06:25:49',
          faceValue: '1000',
          isReward: true
        },
        {
          couponCode: '06',
          activityName: '满1000减999',
          rewardType: '08',
          memberLevelDsc: '',
          validEndTime: '2024-12-31 06:25:49',
          validStartTime: '2024-12-31 06:25:49',
          faceValue: '1000',
          isReward: false
        },
        {
          couponCode: '07',
          activityName: '满1000减999',
          rewardType: '12',
          memberLevelDsc: '',
          validEndTime: '2024-12-31 06:25:49',
          validStartTime: '2024-12-31 06:25:49',
          faceValue: '1000',
          isReward: true
        },
        {
          couponCode: '99',
          activityName: '满1000减999',
          rewardType: '99',
          memberLevelDsc: '',
          validEndTime: '2024-12-31 06:25:49',
          validStartTime: '2024-12-31 06:25:49',
          faceValue: '1000',
          isReward: true
        }
      ],
      couponList: [],
      selection: []
    };
  },
  created() {
    this.getCouponList();
  },
  methods: {
    async getCouponList() {
      const basic = useBasic();
      const res = await basic.gqlClient.query(uaCartQueryCouponsList, {
        input: {
          addressCode: '',
          payType: '',
          storeInputs: {
            orgCode: '10000443',
            distributionType: '11',
            productInputList: [
              {
                productCode: '59849',
                count: 5,
                skuCode: '196040045498',
                selectFlag: '01',
                orgCode: '10000443'
              }
            ],
            couponCodes: '',
            promoCode: ''
          }
        }
      });
      this.couponList = res.data.data[0].filterCouponsByCartResultList;
      console.log(res.data.data);
    },
    onCouponOptionClick(coupon) {
      const index = this.selection.indexOf(coupon.couponCode);
      if (index > -1) {
        this.selection.splice(index, 1);
      } else {
        this.selection.push(coupon.couponCode);
      }
      // console.log('onCouponOptionClick', coupon);
    },
    onShowRule(coupon) {
      console.log('onShowRule', coupon);
    }
  }
};
</script>

<style lang="scss" scoped>
.laboratory {
  width: 335px;
  margin: 0 auto;
}
.coupon-wrapper {
  margin-top: 30px;
  .ua-coupon-option {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
