<template>
    <div id="nuxt-app">
        <Nuxt />      
    </div>
  </template>

  <script>  
  export default {
    name: 'ErrorLayout',
  };
</script>
  