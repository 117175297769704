export const setStorage = (key, data, delay?) => {
  try {
    const expired = typeof delay === 'number' ? new Date().valueOf() + delay : Infinity;
    localStorage.setItem(
      key,
      JSON.stringify({
        data,
        expired,
        expiredString: expired === Infinity ? '' : new Date(expired).toLocaleString()
      })
    );
  } catch (error) {
    throw new Error('setExpireSession error', error);
  }
};

export const getStorage = key => {
  try {
    const session = localStorage.getItem(key);
    if (!session) return null;
    const data = JSON.parse(session);
    if (data.expired < new Date().valueOf()) {
      return null;
    }
    return data.data;
  } catch (error) {
    throw new Error('getExpireSession', error);
  }
};
