import { GuestLogin } from '@/utils/guestLogin.ts';
import { REDIRECT_LOGIN } from "@/../speedshop.config.js";
import { useAccount } from '@speedshop/sdk-api';
import { logout } from '@/graphql/login.gql';
import { GtImage } from '@speedshop/template';

export default {
    name: 'LogoutPage',
    components: {
      GtImage
    },
    data() {
      return {
        accountInstance: null
      };
    },
    methods: {
      async init() {
        const name = this.$route.query.name || '';
        const path = this.$route.query.path || '';
        // localStorage clear
        this.clearLocalStorage();
        // cookie clear
        this.clearAllCookie();
        // 设置为游客
        this.$store.commit('setGuest', true);
        this.$store.commit('setMiniCartNum', 0);
        this.$store.commit('setIsLogin', false);
  
        // 接口 logout
        await this.handleLogoutAjax();
        // 埋点
        this.$sensors.logout();
        this.guestInit();
  
        // 如果是个人中心页面 跳出
        // 如果是其他页面咋当前页面刷新   
        if (!REDIRECT_LOGIN.includes(name) && name) {
              // app.$cookies.remove('token');
              window.location.href = decodeURIComponent(path);
          }else{
             this.goLoginPage();
          }
      },
  
      clearLocalStorage() {
        localStorage?.clear();
      },
  
      clearAllCookie() {
        this.$cookies.remove('token');
        this.$cookies.remove('AUTH-TOKEN');
        localStorage.removeItem('token');
        localStorage.removeItem('AUTH-TOKEN');
        localStorage.removeItem('userInfo');
        sessionStorage.clear();
      },
  
      goLoginPage() {
        window.location.href = window.location.origin + '/';
      },
  
      async handleLogoutAjax() {
        try {
          let {
            data: { api }
          } = await this.accountInstance.gqlClient.query(logout);
  
          return api;
        } catch (err) {
          console.log(err);
        }
      },
  
      async guestInit() {
        if (
          (localStorage &&
            JSON.parse(localStorage.getItem('userInfo')) &&
            JSON.parse(localStorage.getItem('userInfo')).isVisitor) ||
          localStorage.getItem('token')
        ) {
          return;
        }
        GuestLogin && (await GuestLogin(this));
      }
    },
    created() {
      this.accountInstance = useAccount();
    },
    mounted() {
      this.init();
    }
  };