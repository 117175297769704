import { getImgLink } from '@/utils/tools.ts';

export default {
  methods: {
    getUrlWithOss(url = '', { s = '568,584', w, h, bg } = {}) {
      const URL = getImgLink(url)[0];
      const oss = 'x-oss-process=image/quality,q_99/format,webp';
      if (URL.includes('static.underarmour.cn')) {
        if (URL.includes('?')) {
          return URL + `,${oss}`;
        } else {
          return URL + `?${oss}`;
        }
      } else {
        return URL;
      }

      // if (url.includes('assets.underarmour.cn')) {
      //   const oss = `scl=1&fmt=jpg&qlt=80&size=${s}&wid=${w}&hei=${h}&extend=0,0,0,0&cache=on,off&resMode=sharp2&bgc=${bg}`;
      //   // ua oss
      //   if (url.includes('?')) {
      //     return url + `,${oss}`;
      //   } else {
      //     return url + `?${oss}`;
      //   }
      // } else {
      //   const oss = `x-oss-process=image/resize,m_pad,w_${w},h_${h},limit_0,color_${bg},x-oss-process=image/format,webp`;
      //   if (url.includes('?')) {
      //     return url + `,${oss}`;
      //   } else {
      //     return url + `?${oss}`;
      //   }
      // }
      // ?
    }
  }
};
