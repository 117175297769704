import { useShopCart } from '@speedshop/sdk-api';
import { isCouponError } from '@/utils/coupon';
import {
  updateProductSku,
  queryCart,
  checkOutCart,
  deleteCartProductAndWishlist
} from '@/graphql/cart.gql';

export const CART_SCENE = {
  // 添加商品
  // INTERACTIVE_ADD_CART: '11',
  // 删除商品✅
  INTERACTIVE_DEL_CART: '12',
  // 更变规格/增减
  INTERACTIVE_UPDATE_CARTC: '13',
  // 勾选商品✅
  INTERACTIVE_SELECT: '14',
  // 取消勾选商品✅
  INTERACTIVE_UNSELECT: '15',
  // 使用优惠券✅
  INTERACTIVE_USE_COUPON: '16',
  // 取消优惠券 需要做的比较细诶。
  INTERACTIVE_CANCEL_COUPON: '17',
  // 使用优惠码 不用区分吧？
  INTERACTIVE_USE_PROMO_CODE: '18'
};

export const useUaShopCart = (params, _this) => {
  const shopCart = useShopCart(params);
  shopCart.updateProductSku = params => {
    const { productCode, skuCode, newSkuCode, ...others } = params;
    return new Promise((resolve, reject) => {
      shopCart.gqlClient
        .mutate(updateProductSku, {
          input: {
            distributionType: shopCart.distributionType,
            productCode,
            skuCode,
            newSkuCode,
            ...others
          }
        })
        .then(response => {
          const { data } = response.data;
          if (data.success) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  shopCart.queryCartProductList = async params => {
    const {
      couponCodes = shopCart.couponCodes,
      promotionCode = shopCart.promotionCode,
      interactiveSceneType
    } = params || {};
    console.log('couponCodes', couponCodes, promotionCode);

    const {
      data: {
        data,
        miniCartNum: { miniCartNum }
      }
    } = await shopCart.gqlClient.query(queryCart, {
      input: {
        interactiveSceneType,
        distributionType: shopCart.distributionType,
        couponCodes: [...couponCodes, promotionCode].filter(i => i).join(',')
      }
    });
    _this && (_this.limitationResult = data.purchaseConstraintResult);
    return { ...data, miniCartNum, limitationResult: data.purchaseConstraintResult } || {};
  };
  shopCart.getCartProductDataList = async params => {
    const data = await shopCart.queryCartProductList(params);
    const result = await shopCart.formatData(data);
    return result;
  };
  shopCart.checkOutCart = async input => {
    console.log('开始checkout');
    let params = null;
    if (input) {
      params = input;
    } else {
      const { orgCode, products } = shopCart.cartProductData;
      const orgList = [
        {
          orgCode,
          storeCode: shopCart.storeCode,
          products: products
            .filter(i => i.selected)
            .map(v => {
              const { productCode, quantity, skuCode, activityCode, activityType } = v;

              return {
                productCode,
                quantity,
                skuCode,
                activityCode,
                activityType
              };
            })
        }
      ];
      params = {
        distributionType: shopCart.distributionType,
        orgList,
        couponCodes: [...shopCart.couponCodes, shopCart.promotionCode].filter(i => i).join(',')
      };
    }
    const { promoCode, couponCodes, ...others } = params;

    const {
      data: { data }
    } = await shopCart.gqlClient.query(checkOutCart, {
      input: {
        ...others,
        couponCodes: couponCodes || promoCode
      }
    });

    return {
      couponCodes: (params.couponCodes && params.couponCodes.split(',')) || [],
      promotionCode: params.promoCode,
      distributionType: params.distributionType,
      ...data
    };
  };
  shopCart.setSelected = async params => {
    // //勾选商品清空之前选择优惠券和优惠码
    // this.promotionCode = '';
    // this.couponCodes = [];
    const interactiveSceneType = params.selected
      ? CART_SCENE.INTERACTIVE_SELECT
      : CART_SCENE.INTERACTIVE_UNSELECT;
    try {
      await shopCart.checkCartProduct(params);
      const result = await shopCart.getCartProductDataList({
        interactiveSceneType
      });
      return result;
    } catch (e) {
      const code = (e && e.graphQLErrors && e.graphQLErrors[0].code) || '';
      if (isCouponError(code)) {
        shopCart.promotionCode = '';
        shopCart.couponCodes = [];
        const result = await shopCart.getCartProductDataList({
          interactiveSceneType
        });
        return result;
      } else {
        throw e;
      }
    }
  };
  shopCart.changeProductSku = async params => {
    const interactiveSceneType = CART_SCENE.INTERACTIVE_UPDATE_CARTC;
    try {
      const { newSkuData, skuData, newQuantity } = params;
      const { advancedPriceFlag, promotionAmount, promotionPrice } = skuData;
      await shopCart.updateProductSku({
        skuCode: skuData.skuCode,
        newSkuCode: newSkuData.skuCode,
        productCode: skuData.productCode,
        newProductCode: newSkuData.productCode,
        newQuantity,
        priceSetting: String(Number(advancedPriceFlag || false)),
        promotionAmount,
        promotionPrice
      });
      const result = await shopCart.getCartProductDataList({
        interactiveSceneType
      });
      return result;
    } catch (e) {
      const code = (e && e.graphQLErrors && e.graphQLErrors[0].code) || '';
      if (isCouponError(code)) {
        shopCart.promotionCode = '';
        shopCart.couponCodes = [];
        const result = await shopCart.getCartProductDataList({
          interactiveSceneType
        });
        return result;
      } else {
        throw e;
      }
    }
  };
  shopCart.changeProductQty = async params => {
    const interactiveSceneType = CART_SCENE.INTERACTIVE_UPDATE_CARTC;
    try {
      await shopCart.updateProductSku(params);
      const result = await shopCart.getCartProductDataList({
        interactiveSceneType
      });
      return result;
    } catch (e) {
      const code = (e && e.graphQLErrors && e.graphQLErrors[0].code) || '';
      if (isCouponError(code)) {
        shopCart.promotionCode = '';
        shopCart.couponCodes = [];
        const result = await shopCart.getCartProductDataList({
          interactiveSceneType
        });
        return result;
      } else {
        throw e;
      }
    }
  };
  shopCart.deleteSingleProduct = async params => {
    const interactiveSceneType = CART_SCENE.INTERACTIVE_DEL_CART;
    try {
      await shopCart.deleteCartSku(params);
      const result = await shopCart.getCartProductDataList({
        interactiveSceneType
      });
      return result;
    } catch (e) {
      const code = (e && e.graphQLErrors && e.graphQLErrors[0].code) || '';
      if (isCouponError(code)) {
        shopCart.promotionCode = '';
        shopCart.couponCodes = [];
        const result = await shopCart.getCartProductDataList({
          interactiveSceneType
        });
        return result;
      } else {
        throw e;
      }
    }
  };

  shopCart.checkCoupon = async params => {
    const { couponCodes = shopCart.couponCodes, promotionCode = shopCart.promotionCode } =
      params || {};
    const interactiveSceneType = CART_SCENE.INTERACTIVE_USE_COUPON;
    console.log('check coupon', params);
    const result = await shopCart.getCartProductDataList({
      ...params,
      interactiveSceneType
    });
    shopCart.couponCodes = couponCodes;
    shopCart.promotionCode = promotionCode;
    return result;
  };

  shopCart.addToWishList = async params => {
    const interactiveSceneType = CART_SCENE.INTERACTIVE_DEL_CART;
    try {
      await shopCart.gqlClient.mutate(deleteCartProductAndWishlist, {
        input: {
          skuCode: params.skuCode,
          productCode: params.productCode
        }
      });
      const result = await shopCart.getCartProductDataList({
        ...params,
        interactiveSceneType
      });
      return result;
    } catch (e) {
      const code = (e && e.graphQLErrors && e.graphQLErrors[0].code) || '';
      if (isCouponError(code)) {
        shopCart.promotionCode = '';
        shopCart.couponCodes = [];
        const result = await shopCart.getCartProductDataList({
          interactiveSceneType
        });
        return result;
      } else {
        throw e;
      }
    }
  };
  return shopCart;
};
