<template>
  <no-ssr>
    <section class="page-blind-package">
      <main>
        <nuxt-link to="/">
          <svg
            class="pc-logo"
            xmlns="http://www.w3.org/2000/svg"
            width="146"
            height="92"
            viewBox="0 0 146 92"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.17769 92C0.0663555 92 0.0120727 89.4008 0.0120727 88.3994V83.6672C0.0120727 83.3809 -0.00932717 83 0.545244 83H2.15493C2.67741 83 2.64478 83.4049 2.64478 83.6672V88.3994C2.64478 88.7337 2.69881 89.6399 3.83039 89.6399H6.52861C7.63775 89.6399 7.70325 88.7337 7.70325 88.3994V83.6672C7.70325 83.4049 7.67037 83 8.1931 83H9.81349C10.4012 83 10.3469 83.4049 10.3469 83.6672V88.3994C10.3469 89.4008 10.2924 92 7.17008 92H3.17769Z"
              fill="#231F20"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.6022 91.427C17.4664 89.8063 15.8475 87.7672 14.1947 85.6098V91.3672C14.1947 91.6172 14.2513 91.9992 13.6889 91.9992H12.1372C11.5751 91.9992 11.6428 91.6172 11.6428 91.3672V83.6671C11.6428 83.4049 11.6199 83 12.1372 83H15.1614C16.0161 83 17.2304 84.8232 18.0291 86.0273C18.647 86.9454 19.7825 88.3277 20.5472 89.3645V83.6671C20.5472 83.4049 20.5135 83 21.075 83H22.7624C23.3019 83 23.2682 83.4049 23.2682 83.6671V91.9992H20.165C19.5015 91.9992 19.2094 92.0464 18.6022 91.427Z"
              fill="#231F20"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.8527 92V83.0001H33.7482C34.2349 83.0001 37.4715 82.9405 37.4715 86.366C37.4715 89.8633 37.8503 92 33.8191 92H29.5628L28.6391 90.1136V92H25.8527ZM33.3336 89.6242C34.7679 89.6242 34.6612 88.2401 34.6612 87.6073C34.6612 85.4826 34.0685 85.3996 33.12 85.3996H28.6152V89.6242H33.3336Z"
              fill="#231F20"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M40.6015 83H47.2033C47.7629 83 47.6875 83.4063 47.6875 84.1587C47.6875 84.8635 47.7734 85.2587 47.2033 85.2587H41.9885C41.8484 85.2587 41.3756 85.1747 41.3756 85.7252C41.3756 86.2749 41.2783 86.4899 41.8059 86.4899H46.4184C46.4184 86.4899 47.0852 87.9004 47.161 88.1393C47.2684 88.3544 47.2575 88.5585 46.8489 88.5585H42.0747L41.3328 86.9565V89.323C41.3328 89.8724 41.7736 89.7651 41.9023 89.7651H47.3217C47.8593 89.7651 47.8165 90.1835 47.8165 90.8648C47.8165 91.5934 47.8593 92 47.3217 92H40.4614C39.8918 92 38.7731 91.8093 38.7731 89.8248V84.8524C38.7731 84.3382 39.021 83 40.6015 83Z"
              fill="#231F20"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M49.7805 83H57.0539C58.0278 83 59.4455 82.9883 59.4455 85.8876C59.4455 87.7863 59.0779 87.8816 58.2984 88.466C59.6081 88.7163 59.4352 90.5668 59.4352 91.522C59.4352 92.0111 59.2721 91.9996 59.1425 91.9996H57.0105C56.5668 91.9996 56.6753 91.2234 56.6753 90.8177C56.6753 89.707 56.1118 89.7557 55.8953 89.7557H52.7571C52.4538 89.1824 51.8691 87.8934 51.8691 87.8934V91.6291C51.8691 91.9515 51.5775 91.9996 51.4687 91.9996H49.3255C49.445 91.9996 49.1093 92.0231 49.1093 91.7007V83.7757C49.1093 83.2142 49.4668 83 49.7805 83ZM55.5707 85.3754H52.3352C51.7936 85.3754 51.8154 85.5778 51.8154 85.7574V87.3685H55.1488C56.7615 87.3685 56.7615 86.9264 56.7615 86.4614C56.7615 85.53 56.6968 85.3754 55.5707 85.3754Z"
              fill="#231F20"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M74.5633 83C75.1309 83 75.6882 83.2627 76.2911 84.4701C76.6967 85.3309 79.526 90.6016 80.1181 91.7012V92H77.1717L76.3256 90.4229H72.7417C72.7417 90.4229 72.1388 88.9998 71.9641 88.6055C71.7448 88.9763 70.5618 91.2946 70.1553 92H67.1978V91.8093C67.8007 90.6374 71.9187 83 71.9187 83H74.5633ZM73.6351 85.4978C73.5071 85.7252 72.2896 87.9959 72.1972 88.1995C72.1388 88.2947 72.1972 88.3306 72.3244 88.3306H75.0032C75.154 88.3306 75.1885 88.2947 75.1426 88.1995C75.0496 87.9959 73.89 85.7252 73.774 85.4858C73.7392 85.4261 73.6579 85.4261 73.6351 85.4978Z"
              fill="#231F20"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M80.7881 83H88.062C89.036 83 90.4544 82.9883 90.4544 85.8876C90.4544 87.7863 90.0863 87.8816 89.3068 88.466C90.6167 88.7163 90.443 90.5668 90.443 91.522C90.443 92.0111 90.281 91.9996 90.1509 91.9996H88.0192C87.5757 91.9996 87.6834 91.2234 87.6834 90.8177C87.6834 89.707 87.12 89.7557 86.9037 89.7557H83.7655C83.462 89.1824 82.878 87.8934 82.878 87.8934V91.6291C82.878 91.9515 82.5851 91.9996 82.4771 91.9996H80.3345C80.4534 91.9996 80.1182 92.0231 80.1182 91.7007V83.7757C80.1182 83.2142 80.4744 83 80.7881 83ZM86.5786 85.3754H83.3431C82.8022 85.3754 82.8245 85.5778 82.8245 85.7574V87.3685H86.1569C87.7702 87.3685 87.7702 86.9264 87.7702 86.4614C87.7702 85.53 87.7055 85.3754 86.5786 85.3754Z"
              fill="#231F20"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M97.6452 92C97.0682 90.878 95.464 87.7872 94.379 85.7585V91.3673C94.379 91.6175 94.4127 92 93.8598 92H92.2902C91.7013 92 91.7473 91.6175 91.7473 91.3673V83.6577C91.7473 83.3952 91.7138 83.0015 92.2902 83.0015H95.0019C95.4067 83.0015 95.9837 82.9056 96.6299 84.2423C97.1147 85.3165 98.1416 87.5364 98.8457 88.7171C99.5728 87.5364 100.623 85.3165 101.107 84.2423C101.742 82.9056 102.308 83.0015 102.758 83.0015H105.459C106.013 83.0015 105.955 83.3952 105.955 83.6577V91.3673C105.955 91.6175 106.036 92 105.459 92H103.878C103.3 92 103.346 91.6175 103.346 91.3673V85.7585C102.25 87.7872 100.657 90.878 100.058 92H97.6452Z"
              fill="#231F20"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M109.68 92C107.14 92 107.253 89.1701 107.253 87.3339C107.253 85.6524 107.07 83.023 110.25 83H115.891C119.081 83 118.877 85.664 118.877 87.3339C118.877 89.1701 119.001 92 116.426 92H109.68ZM114.785 89.7019C116.074 89.7019 116.027 88.3175 116.027 87.3928C116.027 86.5643 116.209 85.2612 114.534 85.2612H111.697C110.033 85.2612 110.227 86.5643 110.227 87.3928C110.227 88.3175 110.147 89.7019 111.435 89.7019H114.785Z"
              fill="#231F20"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M123.337 92C120.225 92 120.171 89.4008 120.171 88.3994V83.6672C120.171 83.3809 120.149 83 120.704 83H122.314C122.836 83 122.804 83.4049 122.804 83.6672V88.3994C122.804 88.7337 122.858 89.6399 123.99 89.6399H126.688C127.798 89.6399 127.862 88.7337 127.862 88.3994V83.6672C127.862 83.4049 127.83 83 128.352 83H129.973C130.561 83 130.506 83.4049 130.506 83.6672V88.3994C130.506 89.4008 130.452 92 127.33 92H123.337Z"
              fill="#231F20"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M132.47 83H139.744C140.718 83 142.136 82.9883 142.136 85.8876C142.136 87.7863 141.768 87.8816 140.988 88.466C142.298 88.7163 142.125 90.5668 142.125 91.522C142.125 92.0111 141.962 91.9996 141.832 91.9996H139.701C139.256 91.9996 139.365 91.2234 139.365 90.8177C139.365 89.707 138.802 89.7557 138.586 89.7557H135.447C135.144 89.1824 134.56 87.8934 134.56 87.8934V91.6291C134.56 91.9515 134.267 91.9996 134.159 91.9996H132.016C132.136 91.9996 131.8 92.0231 131.8 91.7007V83.7757C131.8 83.2142 132.157 83 132.47 83ZM138.261 85.3754H135.025C134.484 85.3754 134.505 85.5778 134.505 85.7574V87.3685H137.839C139.451 87.3685 139.451 86.9264 139.451 86.4614C139.451 85.53 139.387 85.3754 138.261 85.3754Z"
              fill="#231F20"
            ></path>
            <mask
              id="mask0_1464_52976"
              style="mask-type: luminance"
              maskUnits="userSpaceOnUse"
              x="143"
              y="89"
              width="3"
              height="3"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M143.643 89.658H145.988V92H143.643V89.658Z"
                fill="white"
              ></path>
            </mask>
            <g mask="url(#mask0_1464_52976)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M143.643 90.8108C143.643 90.1694 144.223 89.658 144.811 89.658C145.474 89.658 145.988 90.1694 145.988 90.8108C145.988 91.452 145.456 92 144.784 92C144.139 92 143.643 91.452 143.643 90.8108ZM144.811 91.7122C145.334 91.7122 145.708 91.322 145.708 90.8108C145.708 90.3085 145.325 89.9371 144.784 89.9371C144.326 89.9371 143.896 90.346 143.896 90.8108C143.896 91.322 144.298 91.7122 144.811 91.7122ZM144.354 91.3682V90.3645C144.354 90.2162 144.372 90.1791 144.512 90.1791H145.026C145.269 90.1791 145.399 90.3748 145.399 90.5694C145.399 90.774 145.306 90.9317 145.101 90.9317C145.167 91.0528 145.26 91.2294 145.325 91.3405C145.418 91.5085 145.167 91.5451 145.129 91.5085C144.979 91.294 144.885 91.108 144.784 90.9597H144.606V91.4057C144.606 91.5265 144.503 91.5365 144.447 91.5365C144.354 91.5365 144.354 91.4428 144.354 91.3682ZM144.597 90.6717H144.924C145.091 90.6717 145.119 90.6251 145.119 90.5508V90.5322C145.119 90.4391 145.091 90.3831 144.924 90.3831H144.597V90.6717Z"
                fill="#231F20"
              ></path>
            </g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M72.152 40.3486H72.1423L71.9088 40.3456C64.4947 40.3456 58.4514 38.4304 54.0214 35.5051C58.4525 32.5738 64.501 30.6538 71.9242 30.6538L72.1571 30.6514H72.1662L72.3997 30.6538C79.8144 30.6538 85.8571 32.5702 90.2876 35.4949C85.856 38.4256 79.8081 40.3456 72.3849 40.3456L72.152 40.3486ZM108.146 35.4967C120.666 29.6893 128.839 20.9687 129.282 11.2937C129.282 11.2937 125.981 8.60744 115.311 4.46966C105.937 0.834505 98.7913 0 98.7913 0L98.8226 21.5593C98.8226 24.7799 97.5972 28.0694 95.2828 30.9791C88.1999 29.1741 80.3734 28.1641 72.1645 28.1617H72.1622H72.1594C63.9408 28.1641 56.1058 29.1765 49.016 30.9851C46.6982 28.0742 45.4706 24.7823 45.4706 21.5593L45.503 0C45.503 0 38.357 0.834505 28.9824 4.46966C18.3126 8.60744 15.012 11.2937 15.012 11.2937C15.4545 20.9722 23.6344 29.6959 36.1625 35.5039C23.6424 41.3107 15.4698 50.0313 15.0262 59.7063C15.0262 59.7063 18.3273 62.3926 28.9972 66.5303C38.3717 70.1655 45.5177 71 45.5177 71L45.4859 49.4407C45.4859 46.2207 46.7119 42.9312 49.0257 40.0203C56.1092 41.8271 63.9352 42.8353 72.1435 42.8377H72.1469H72.1497C80.3677 42.8353 88.2033 41.8235 95.2925 40.0143C97.6103 42.9258 98.8385 46.2183 98.8385 49.4407L98.8061 71C98.8061 71 105.952 70.1655 115.326 66.5303C125.997 62.3926 129.298 59.7063 129.298 59.7063C128.854 50.0278 120.675 41.3041 108.146 35.4967Z"
              fill="#1D1D1D"
            ></path>
          </svg>
        </nuxt-link>
        <h4>员工闷包活动</h4>

        <gt-button size="large" type="primary" :loading="loading" @click="handleClick">
          {{ text }}
        </gt-button>
        <p v-if="$store.getters.isMobile">
          请提前用员工账号登录
          <br />
          以免无法查看闷包商品
        </p>
        <p v-else>请提前用员工账号登录，以免无法查看闷包商品</p>
      </main>
    </section>
  </no-ssr>
</template>

<script>
import { GtButton } from '@speedshop/template';
import { Message } from 'element-ui';
import { useBasic } from '@speedshop/sdk-api';
import { checkStuffyBag } from '@/graphql/blindPackage.gql';
export default {
  components: { GtButton },
  data() {
    return {
      text: '进入购买页',
      loading: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      //
    },
    finish() {
      this.isStarted = true;
    },
    async handleClick() {
      this.loading = true;
      // this.text = '进入购买页中...';
      try {
        const productNo = await this.getUrl();
        // 随机等待1-10秒进入闷包pdp
        const waitTime = Math.floor(Math.random() * 10 + 1) * 500;
        setTimeout(() => {
          this.$router.push({ name: 'BlindPackagePDP', params: { code: productNo } });
          this.loading = false;
        }, waitTime);
      } catch (e) {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      } finally {
        //
      }
    },
    async getUrl() {
      const Basic = useBasic();
      const {
        data: {
          data: { message, code, productNo }
        }
      } = await Basic.gqlClient.query(checkStuffyBag);
      console.log(message, code, productNo);
      if (productNo && code === '0000') {
        // 活动已开始
        return productNo;
      } else if (code) {
        Message({
          type: 'warning',
          message,
          customClass: 'blind-package-message-tips',
          duration: 1000
        });
        return Promise.reject(message);
      }
    }
  }
};
</script>
<style lang="less">
.el-message.blind-package-message-tips {
  top: calc(50% - 210px) !important;
}
.page-blind-package {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(https://oms-static.underarmour.cn/staging/200005/20240419/FAB8480AA46C44D025EA913B6C17FEFA.png);
  main {
    width: 680px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    padding: 110px 180px;
    align-items: center;
    h4 {
      font-weight: 500;
      font-size: 32px;
      color: #000000;
      line-height: 45px;
      margin: 40px 0px;
    }
    .gt-button {
      width: 100%;
    }
    svg {
      width: 94px;
      height: auto;
    }
    p {
      margin-top: 24px;
      font-size: 14px;
      text-align: center;
    }
  }
}
@media (max-width: 765px) {
  .page-blind-package {
    background-position: center;
    background-size: cover;
    background-image: url(https://oms-static.underarmour.cn/staging/200005/20240419/653ADB9D1997D6620A6A033DC085BA4C.png);
    background-repeat: no-repeat;
    padding: 0px 40px;
    main {
      width: 100%;
      padding: 60px 30px;
      margin-top: -15vh;
      svg {
        width: 68px;
        height: auto;
      }
      h4 {
        margin: 30px 0px 30px 0px;
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}
</style>
