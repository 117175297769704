import Vue from 'vue';
import {
  Slider,
  SwipeCell,
  Button,
  Area,
  Collapse,
  CollapseItem,
  Popup,
  Swipe,
  SwipeItem,
  Sticky,
  Picker,
  Overlay,
  DatetimePicker,
  CountDown
} from 'vant';
import ImgIcon from '../components/imgIcon.vue';
import DrawerLogin from '../components/Login/drawerLogin.vue';
Vue.component('ImgIcon', ImgIcon);
Vue.component('DrawerLogin', DrawerLogin);

Vue.use(Slider);
Vue.use(Swipe);
Vue.use(SwipeCell);
Vue.use(SwipeItem);
Vue.use(Button);
Vue.use(Area);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Popup);
Vue.use(Sticky);
Vue.use(Picker);
Vue.use(Overlay);
Vue.use(DatetimePicker);
Vue.use(CountDown);

export default ({ _app }) => {
  import('vant/lib/slider/style/less');
  import('vant/lib/swipe/style/less');
  import('vant/lib/swipe-cell/style/less');
  import('vant/lib/swipe-item/style/less');
  import('vant/lib/button/style/less');
  import('vant/lib/area/style/less');
  import('vant/lib/collapse/style/less');
  import('vant/lib/collapse-item/style/less');
  import('vant/lib/popup/style/less');
  import('vant/lib/sticky/style/less');
  import('vant/lib/picker/style/less');
  import('vant/lib/overlay/style/less');
  import('vant/lib/datetime-picker/style/less');
};
