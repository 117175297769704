<template>
  <div
    :class="{
      'cms-html-edit': true,
      'checkout-page-footer': onlyShowBottom
    }"
    code="1566919402505"
  >
    <footer class="footer">
      <div class="footer-main">
        <div class="footer-top">
          <div class="footer-inner float-clearfix">
            <div class="ua-info">
              <img
                :style="{ 'margin-bottom': isMobile ? '16px' : '32px' }"
                :src="require(`@/assets/image/logo_en.svg`)"
                alt="logo_en"
              />

              <p class="title">获取UA最新资讯</p>
              <div :key="$route.path" class="from-subscribe">
                <div class="form-group form-group-btn">
                  <label class="control-label" aria-label="请输入电子邮箱">请输入电子邮箱</label>
                  <div class="form-input">
                    <input
                      id="subscribe-email-input"
                      v-model="emailText"
                      style="height: 40px"
                      aria-label="footerSubscribe"
                      maxlength="50"
                      autocomplete="off"
                      type="text"
                      @blur="onSubscribe(true)"
                      @keyup.enter="onSubscribe(false)"
                    />
                    <!--
                      readonly=""
                      @focus="removeAttribute('readonly')"
                      @blur="setAttribute('readonly', true)" -->
                    <i class="icon icon-input-success"></i>
                    <div class="msg-block">
                      <span></span>
                    </div>
                    <a
                      id="footerSubscribe"
                      v-click-track:buttonClick="{
                        module_name: '底部-订阅',
                        button_name: '订阅'
                      }"
                      class="commit-btn"
                      @click="onSubscribe(false)"
                    >
                      订阅
                    </a>
                  </div>
                </div>
              </div>
              <div class="contact-us" style="position: relative">
                <p class="title">关注我们</p>
                <ul>
                  <template v-for="(item, index) in platforms">
                    <li :key="index" class="platform-item">
                      <template v-if="!item.QRcode">
                        <a :href="item.link" target="_blank" rel="nofollow">
                          <GtImage class="external-img" :src="item.icon" :alt="item.iconAlt" />
                        </a>
                      </template>
                      <template v-else>
                        <a href="#" rel="nofollow" @click.stop.prevent="onWachatPopupShow(item)">
                          <GtImage class="external-img" :src="item.icon" :alt="item.iconAlt" />
                        </a>
                        <div class="platform-item__popper">
                          <GtImage :src="item.QRcode" :alt="item.QRcodeAlt" />
                        </div>
                      </template>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <div class="ua-contact none block-sm telephone-Service">
              <div>
                <i class="iconfont icon-c-16-1px-comments-new"></i>
                <span
                  v-click-track:buttonClick="{
                    module_name: '底部-在线客服',
                    button_name: '在线客服'
                  }"
                  class="customer-service-btn"
                  @click="handleCustomerService"
                >
                  在线客服
                </span>
              </div>
              <div>
                <i class="iconfont icon-a-16-1px-phone2"></i>
                <a href="tel:4008-206-528">4008-206-528</a>
              </div>
            </div>

            <div class="ua-service menu-item-mobile" @click="activeMenu('ua-service')">
              <p class="title">
                {{ navFooterList[0] ? navFooterList[0].navigationName : '' }}
                <a rel="nofollow" href="#" class="none block-sm"></a>
                <i v-if="isMobile" class="icon-a-16-1px-down-s iconfont"></i>
              </p>
              <ul v-if="navFooterList[0]">
                <li v-for="(item, index) in navFooterList[0].children" :key="index">
                  <a
                    v-click-track:buttonClick="{
                      module_name: `底部-${item.navigationTitle}`,
                      button_name: `${item.navigationTitle}`
                    }"
                    :href="item.navigationUrl"
                    rel="nofollow"
                  >
                    {{ item.navigationTitle }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="ua-order menu-item-mobile" @click="activeMenu('ua-order')">
              <p class="title">
                {{ navFooterList[1] ? navFooterList[1].navigationName : '' }}
                <a href="#" rel="nofollow" class="none block-sm"></a>
                <i v-if="isMobile" class="icon-a-16-1px-down-s iconfont"></i>
              </p>
              <ul v-if="navFooterList[1]">
                <!-- <li>
                  <a href="/guestSearchSalesOrder" class="e-track-order" rel="nofollow"
                    >游客订单查询</a
                  >
                </li> -->
                <li v-for="(item, index) in navFooterList[1].children" :key="index">
                  <a
                    v-click-track:buttonClick="{
                      module_name: `底部-${item.navigationTitle}`,
                      button_name: `${item.navigationTitle}`
                    }"
                    :href="item.navigationUrl"
                    rel="nofollow"
                  >
                    {{ item.navigationTitle }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="ua-story menu-item-mobile" @click="activeMenu('ua-story')">
              <p class="title">
                {{ navFooterList[2] ? navFooterList[2].navigationName : '' }}
                <a href="#" rel="nofollow" class="none block-sm"></a>
                <i v-if="isMobile" class="icon-a-16-1px-down-s iconfont"></i>
              </p>
              <ul v-if="navFooterList[2]">
                <li v-for="(item, index) in navFooterList[2].children" :key="index">
                  <a
                    v-click-track:buttonClick="{
                      module_name: `底部-${item.navigationTitle}`,
                      button_name: `${item.navigationTitle}`
                    }"
                    :href="item.navigationUrl"
                    rel="nofollow"
                  >
                    {{ item.navigationTitle }}
                  </a>
                </li>
              </ul>
            </div>
            <div
              v-if="isMobile"
              class="ua-mendian menu-item-mobile"
              @click="activeMenu('ua-mendian')"
            >
              <p class="title">
                线下门店
                <a href="#" rel="nofollow" class="none block-sm"></a>
                <i v-if="isMobile" class="icon-a-16-1px-down-s iconfont"></i>
              </p>
              <ul>
                <li>
                  <a href="/storelocator/index.htm" rel="nofollow">全部门店</a>
                </li>
              </ul>
            </div>
            <div class="ua-contact none-sm">
              <p class="title">联系方式</p>
              <ul>
                <li>
                  <a id="tel_mobile6" :href="isMobile ? 'tel:4008-206-528' : 'javascript:void(0)'">
                    <svg
                      class="ua-contact-svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3141_60728)">
                        <path
                          d="M9.22578 16.3178L13.9258 20.8908C14.0992 21.0595 14.3279 21.1596 14.5695 21.1726L20.4827 21.4905C21.0356 21.5202 21.5074 21.0949 21.5351 20.5419L21.7489 16.2649C21.7764 15.7161 21.3559 15.248 20.8072 15.2166L16.7586 14.9853C16.474 14.969 16.196 15.075 15.9945 15.2765L14.7361 16.5349C14.3491 16.9219 13.7229 16.9259 13.331 16.5439L10.9618 14.2345L8.45708 11.6689C8.07455 11.277 8.07831 10.6504 8.46553 10.2632L9.72332 9.00539C9.92489 8.80383 10.0308 8.52583 10.0146 8.24124L9.78324 4.19261C9.75189 3.644 9.28375 3.22347 8.73493 3.25091L4.45796 3.46476C3.9049 3.49241 3.47961 3.96424 3.50934 4.51719L3.82721 10.4296C3.84023 10.6716 3.94072 10.9007 4.11 11.0742L9.22578 16.3178Z"
                          stroke="#14141E"
                          stroke-miterlimit="10"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3141_60728">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span>4008-206-528</span>
                  </a>
                </li>
                <li id="live800ClickFooter">
                  <a href="#" rel="nofollow">
                    <!-- <svg
                      class="ua-contact-svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask id="path-1-inside-1_3141_58332" fill="white">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3 4C2.44772 4 2 4.44772 2 5V18C2 18.5523 2.44772 19 3 19H5V21.5044C5 21.906 5.44959 22.1437 5.78151 21.9176L10.0642 19H21C21.5523 19 22 18.5523 22 18V5C22 4.44772 21.5523 4 21 4H3Z"
                        />
                      </mask>
                      <path
                        d="M5 19H6C6 18.4477 5.55228 18 5 18V19ZM5.78151 21.9176L5.21849 21.0912H5.21849L5.78151 21.9176ZM10.0642 19V18C9.86338 18 9.66719 18.0605 9.5012 18.1736L10.0642 19ZM3 5V5V3C1.89543 3 1 3.89543 1 5H3ZM3 18V5H1V18H3ZM3 18H3H1C1 19.1046 1.89543 20 3 20V18ZM5 18H3V20H5V18ZM6 21.5044V19H4V21.5044H6ZM5.21849 21.0912C5.55041 20.865 6 21.1028 6 21.5044H4C4 22.7092 5.34877 23.4224 6.34452 22.744L5.21849 21.0912ZM9.5012 18.1736L5.21849 21.0912L6.34452 22.744L10.6272 19.8264L9.5012 18.1736ZM21 18H10.0642V20H21V18ZM21 18V20C22.1046 20 23 19.1046 23 18H21ZM21 5V18H23V5H21ZM21 5H23C23 3.89543 22.1046 3 21 3V5ZM3 5H21V3H3V5Z"
                        fill="#14141E"
                        mask="url(#path-1-inside-1_3141_58332)"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7 12C7.27614 12 7.5 11.7761 7.5 11.5C7.5 11.2239 7.27614 11 7 11C6.72386 11 6.5 11.2239 6.5 11.5C6.5 11.7761 6.72386 12 7 12ZM7 13C7.82843 13 8.5 12.3284 8.5 11.5C8.5 10.6716 7.82843 10 7 10C6.17157 10 5.5 10.6716 5.5 11.5C5.5 12.3284 6.17157 13 7 13ZM12 12C12.2761 12 12.5 11.7761 12.5 11.5C12.5 11.2239 12.2761 11 12 11C11.7239 11 11.5 11.2239 11.5 11.5C11.5 11.7761 11.7239 12 12 12ZM12 13C12.8284 13 13.5 12.3284 13.5 11.5C13.5 10.6716 12.8284 10 12 10C11.1716 10 10.5 10.6716 10.5 11.5C10.5 12.3284 11.1716 13 12 13ZM17.5 11.5C17.5 11.7761 17.2761 12 17 12C16.7239 12 16.5 11.7761 16.5 11.5C16.5 11.2239 16.7239 11 17 11C17.2761 11 17.5 11.2239 17.5 11.5ZM18.5 11.5C18.5 12.3284 17.8284 13 17 13C16.1716 13 15.5 12.3284 15.5 11.5C15.5 10.6716 16.1716 10 17 10C17.8284 10 18.5 10.6716 18.5 11.5Z"
                        fill="#14141E"
                      />
                    </svg> -->
                    <span class="ua-contact-svg iconfont icon-c-16-1px-comments-new"></span>

                    <span
                      v-click-track:buttonClick="{
                        module_name: '底部-联系方式',
                        button_name: '联系方式'
                      }"
                      class="customer-service-btn"
                      @click="callParentMethod"
                    >
                      在线客服
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    v-click-track:buttonClick="{
                      module_name: '底部-线下门店',
                      button_name: '线下门店'
                    }"
                    href="/storelocator/index.htm"
                  >
                    <svg
                      class="ua-contact-svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2959 22.147L11.2912 22.1417C9.85034 20.5141 8.14367 18.2846 6.80024 15.9031C5.4503 13.51 4.5 11.0206 4.5 8.85754C4.5 4.76692 7.854 1.5 12.0001 1.5C16.1459 1.5 19.5 4.76692 19.5 8.85754C19.5 11.0206 18.5499 13.51 17.2 15.903C15.8567 18.2846 14.1501 20.5141 12.7089 22.1417L12.7072 22.1437C12.709 22.1416 12.7086 22.142 12.7062 22.1444C12.6923 22.1587 12.6097 22.2435 12.4854 22.3283C12.3336 22.4319 12.1716 22.5 12.0254 22.5H11.9746C11.8284 22.5 11.6663 22.4319 11.5145 22.3283C11.3976 22.2486 11.3189 22.1698 11.3 22.1509C11.2965 22.1473 11.295 22.1459 11.2959 22.147Z"
                        stroke="#14141E"
                      />
                      <path
                        d="M8.5 9C8.5 7.067 10.067 5.5 12 5.5C13.933 5.5 15.5 7.067 15.5 9C15.5 10.933 13.933 12.5 12 12.5C10.067 12.5 8.5 10.933 8.5 9Z"
                        stroke="#14141E"
                      />
                    </svg>

                    <span>线下门店</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <ul class="float-clearfix none-sm">
          <li>Copyright ©2021 Under Armour, Inc.</li>
          <li class="divider">|</li>
          <li>
            <a
              v-click-track:buttonClick="{
                module_name: '底部-隐私政策',
                button_name: '隐私政策'
              }"
              href="https://privacy.underarmour.com/s/article/Global-Privacy-Policy?language=zh_CN"
              target="_blank"
            >
              隐私政策
            </a>
          </li>
          <li class="divider">|</li>
          <li>
            <a
              v-click-track:buttonClick="{
                module_name: '底部-使用规则',
                button_name: '使用规则'
              }"
              href="/help/terms-of-use"
            >
              使用规则
            </a>
          </li>
          <li class="divider">|</li>
          <li>
            <a
              v-click-track:buttonClick="{
                module_name: '底部-网站地图',
                button_name: '网站地图'
              }"
              href="/help/webside-nav"
            >
              网站地图
            </a>
          </li>
          <li class="divider">|</li>
          <li>
            <a
              v-click-track:buttonClick="{
                module_name: '底部-沪ICP备',
                button_name: '沪ICP备'
              }"
              href="http://beian.miit.gov.cn"
              rel="nofollow"
            >
              沪ICP备12034417号-1
            </a>
          </li>
          <li class="divider">|</li>
          <li class="m-icon">
            <a
              v-click-track:buttonClick="{
                module_name: '底部-电子营业执照',
                button_name: '电子营业执照'
              }"
              href="http://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&amp;serial=9031000020160422212721000000695527-SAIC_SHOW_310000-gs20140504000002404443&amp;signData=MEQCIFYiI0szhG7ISW1W3z9DDlUOzeuBpdZ95XKelB3Mp4v/AiBV1u8r2LgU6S7FdGdgohpiSUsNKq4BiB0ONzxLyI13mg=="
              rel="nofollow"
            >
              <i class="icon icon-footer-badge1"></i>
              电子营业执照
            </a>
          </li>
          <li class="divider">|</li>
          <li class="m-ico">
            <a
              v-click-track:buttonClick="{
                module_name: '底部-沪公网安备',
                button_name: '沪公网安备'
              }"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102003916"
              rel="nofollow"
            >
              <i class="icon icon-footer-badge2"></i>
              沪公网安备 31010102003916号
            </a>
          </li>
        </ul>
        <mobileFooterBottom></mobileFooterBottom>
      </div>
    </footer>
    <!-- <Login :visible.sync="showLogin" :show-guest="showGuest" /> -->
    <van-popup v-model="wechatPopupDisplay" position="bottom" style="height: 170px">
      <div
        v-if="currentPlatform && currentPlatform.mbQRcode"
        style="height: 100%; overflow: hidden; text-align: center; position: relative"
      >
        <GtImage
          style="width: 320px; height: 100%; background: #fff; display: inline-block"
          :src="currentPlatform.mbQRcode"
          :alt="currentPlatform.QRcodeAlt"
        />
      </div>
      <img
        class="van-icon-close"
        src="@/assets/ua-icon24/icon-close.svg"
        alt="icon-close"
        @click="wechatPopupDisplay = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { useSubscribe, useBasic } from '@speedshop/sdk-api';
import { GtMessageBox, GtImage } from '@speedshop/template';
import { mapGetters } from 'vuex';
import mobileFooterBottom from './mobileFooterBottom.vue';
import { createSubscribeUANotification } from '@/graphql/common.gql';
import Login from '@/components/Login/drawerLogin.vue';

export default {
  name: 'Footer',
  components: {
    Login,
    GtImage,
    mobileFooterBottom
  },
  props: {
    onlyShowBottom: {
      default: false,
      type: Boolean
    },
    navFooterList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultCallback: null,
      wechatPopupDisplay: false,
      currentPlatform: undefined,
      showGuest: false,
      showLogin: false,
      emailText: ''
    };
  },

  computed: {
    ...mapGetters(['isMobile', 'isGuest', 'isLogin']),
    platforms() {
      const pcList = [
        {
          iconAlt: '微信',
          icon: require('@/assets/image/platform/wechat_gray.png'),
          QRcodeAlt: '微信公众号二维码',
          QRcode: require('@/assets/image/platform/微信公众号-UA官方账号二维码-s.png'),
          mbQRcode: require('@/assets/image/platform/微信公众号-UA官方账号二维码.png'),
          link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA3MjE5MjMyNg==&utm_source=wechat&utm_medium=banner&utm_channel=media&utm_content=230923'
        },
        {
          iconAlt: '微博',
          icon: require('@/assets/image/platform/weibo_gray.png'),
          link: 'https://www.weibo.com/underarmour?sudaref=www.baidu.com&amp;is_hot=1',
          needLink: true
        },
        {
          iconAlt: '抖音',
          icon: require('@/assets/image/platform/douyin_gray.png'),
          QRcodeAlt: '抖音官方账号',
          QRcode: require('@/assets/image/platform/抖音-UA官方账号二维码-s.png'),
          mbQRcode: require('@/assets/image/platform/抖音-UA官方账号二维码.png'),
          link: 'https://v.douyin.com/iduMdYgX',
          needLink: this.isMobile
        },
        {
          iconAlt: '小红书',
          icon: require('@/assets/image/platform/xiaohongshu_gray.png'),
          QRcodeAlt: '小红书官方账号',
          QRcode: require('@/assets/image/platform/小红书-UA官方账号二维码-s.png'),
          mbQRcode: require('@/assets/image/platform/小红书-UA官方账号二维码.png'),
          link: 'https://dwz.cn/kAS9CiLo',
          needLink: this.isMobile
        },
        {
          iconAlt: '安德玛',
          icon: require('@/assets/image/platform/ua_gray.png'),
          QRcodeAlt: '安德玛微信小程序',
          QRcode: require('@/assets/image/platform/UA house 二维码-s.png'),
          mbQRcode: require('@/assets/image/platform/UA house 二维码.png'),
          link: 'https://dwz.cn/GjB9dMkp',
          needLink: this.isMobile
        }
      ];
      const mobileList = [
        {
          iconAlt: '微信',
          icon: require('@/assets/image/platform/wechat_gray.png'),
          QRcodeAlt: '微信公众号二维码',
          QRcode: require('@/assets/image/platform/微信公众号-UA官方账号二维码-s.png'),
          mbQRcode: require('@/assets/image/platform/微信公众号-UA官方账号二维码.png'),
          link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA3MjE5MjMyNg==&utm_source=wechat&utm_medium=banner&utm_channel=media&utm_content=230923'
        },
        {
          iconAlt: '微博',
          icon: require('@/assets/image/platform/weibo_gray.png'),
          // link: 'https://www.weibo.com/underarmour?sudaref=www.baidu.com&amp;is_hot=1'
          link: 'https://www.weibo.com/underarmour?sudaref%20www.baidu.com&amp%20is%20hot%201'
        },
        {
          iconAlt: '抖音',
          icon: require('@/assets/image/platform/douyin_gray.png'),
          QRcodeAlt: '抖音官方旗舰店',
          QRcode: require('@/assets/image/platform/抖音-UA官方账号二维码-s.png'),
          mbQRcode: require('@/assets/image/platform/抖音-UA官方账号二维码.png'),
          link: 'https://www.douyin.com/user/MS4wLjABAAAAkYfLRcmWjpfEpMZJcLg9jTC1SoystxLI6a-O6pdc-Ew'
        },
        {
          iconAlt: '小红书',
          icon: require('@/assets/image/platform/xiaohongshu_gray.png'),
          QRcodeAlt: '小红书官方账号',
          QRcode: require('@/assets/image/platform/小红书-UA官方账号二维码-s.png'),
          mbQRcode: require('@/assets/image/platform/小红书-UA官方账号二维码.png'),
          link: 'https://www.xiaohongshu.com/user/profile/56309daf9eb578712db997cb?m_source=pinpai'
        },
        {
          iconAlt: '安德玛',
          icon: require('@/assets/image/platform/ua_gray.png'),
          QRcodeAlt: '安德玛微信小程序',
          QRcode: require('@/assets/image/platform/UA house 二维码-s.png'),
          mbQRcode: require('@/assets/image/platform/UA house 二维码.png')
        }
      ];
      return this.isMobile ? mobileList : pcList;
    }
  },
  mounted() {
    const $input = document.getElementById('subscribe-email-input');
    $input && $input.setAttribute('autocomplete', 'off');
    this.subscribe = useSubscribe();
    this.toggleEmailInputState(false);

    this.$eventBus.$on('drawLoginSuccessCallback', data => {
      this.defaultCallback && this.defaultCallback();
    });
  },

  methods: {
    callParentMethod() {
      this.$emit('custom-event');
    },

    handleCustomerService() {
      if (this.isLogin && !this.isGuest) {
        this.$customerService.open();
      } else {
        this.defaultCallback = () => {
          this.$customerService.open();
        };
        this.$eventBus.$emit('LoginShow', { showLogin: true, showGuest: true });
      }
    },

    activeMenu(className) {
      let $actived;
      let isSelf = false;
      if (($actived = document.querySelector('.menu-item-mobile.is-active'))) {
        isSelf = $actived.className.includes(className);
        $actived.classList.remove('is-active');
      }

      if (isSelf) return;

      document.querySelector(`.menu-item-mobile.${className}`).classList.add('is-active');
    },

    onWachatPopupShow(item) {
      // 判断是否是移动端
      if (!document.querySelector('.page-layout_mobile')) return;

      this.wechatPopupDisplay = true;
      this.currentPlatform = item;
    },

    toggleEmailInputState(iserror = false, message = '') {
      if (iserror) {
        document.querySelector('.from-subscribe .form-group-btn').classList.add('is-error');
        document.querySelector('.from-subscribe .form-input').classList.add('is-error');
        document.querySelector('.from-subscribe .control-label').textContent = message;
      } else {
        document.querySelector('.from-subscribe .form-group-btn').classList.remove('is-error');
        document.querySelector('.from-subscribe .form-input').classList.remove('is-error');
        document.querySelector('.from-subscribe .control-label').textContent = '请输入电子邮箱';
      }

      //
    },
    dialPhone() {
      const phoneNumber = '4008-206-528';
      window.open('tel:' + phoneNumber);
    },

    handleValid() {
      const email = (this.emailText && this.emailText.trim()) || '';
      if (!email) {
        this.toggleEmailInputState(true, '请输入电子邮箱');
      }
      if (!this?.commonConfig?.emailPattern.test(email)) {
        this.toggleEmailInputState(true, '邮箱格式不正确');
      } else {
        this.toggleEmailInputState(false);
      }
    },

    toggleSubscribeResultDialog(flag) {
      try {
        if (flag) {
          this.$trackHelp.track('popupExpose', {
            popup_name: '订阅成功'
          });
          GtMessageBox.confirm('订阅成功', '温馨提醒', {
            confirmButtonText: '确定',
            showCancelButton: false,
            type: '',
            center: true
          });
        } else {
          this.$trackHelp.track('popupExpose', {
            popup_name: '订阅失败'
          });
          GtMessageBox.confirm('订阅失败', '温馨提醒', {
            confirmButtonText: '确定',
            showCancelButton: false,
            type: '',
            center: true
          });
        }
      } catch (err) {
        console.log(err);
      }
    },

    async onSubscribe(blur = false) {
      const value = this.emailText;
      const email = (value && value.trim()) || '';
      if (!email) {
        this.toggleEmailInputState(true, '请输入电子邮箱');
        return;
      }

      if (!this?.commonConfig?.emailPattern.test(email)) {
        this.toggleEmailInputState(true, '邮箱格式不正确');
        return;
      }

      this.toggleEmailInputState(false);

      if (!blur) {
        try {
          const basic = useBasic();
          const res = await basic.gqlClient.query(createSubscribeUANotification, {
            input: { email }
          });

          if (res && res.data && res.data && res.data.api && res.data.api.success) {
            this.toggleSubscribeResultDialog(true);
          } else {
            this.toggleSubscribeResultDialog(false);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.icon {
  background-image: url(~/assets/image/icon_spite.png);
  display: inline-block;
}
.footer {
  .icon {
    background-size: 342px 338px;
    display: inline-block;
  }
}

.checkout-page-footer {
  .footer-bottom {
    border-top: none;
  }
}

.checkout-page-footer {
  .footer {
    padding-top: 0;
  }
  .footer-top {
    display: none;
  }
  .footer-bottom {
    padding-top: 24px;
    padding-bottom: 8px;
    display: block;
  }
}

.page-layout_mobile {
  .footer {
    .footer-top {
      .footer-inner > div:nth-child(n + 2) .title > a {
        .icon.icon-a-16-1px-down-s {
          margin-bottom: 0px;
          padding-bottom: 3px;
        }
      }
    }
  }
}

.menu-item-mobile {
  &.is-active {
    .icon.icon-a-16-1px-down-s {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    ul {
      display: block !important;
    }
    ul {
      li:last-child {
        margin-bottom: 14px !important;
      }
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
}
</style>

<style>
.form-input {
  position: relative;
}

.form-input input {
  height: 38px;
}

.form-input input,
.form-input textarea {
  font-size: 14px;
  width: 320px;
  background: var(--main-color-ua-white, #fff);
  border: 1px solid #ddd;
  border-radius: 1px;
  padding: 5px 46px 5px 16px;
  color: var(--secondary-color-ua-cool-grey-04, #50505a);
}

.form-input input::-webkit-input-placeholder,
.form-input textarea::-webkit-input-placeholder {
  font-size: 12px;
  color: #999;
  letter-spacing: 0;
}

.form-input input::-moz-placeholder,
.form-input textarea::-moz-placeholder {
  font-size: 12px;
  color: #999;
  letter-spacing: 0;
}

.form-input input::-ms-input-placeholder,
.form-input textarea::-ms-input-placeholder {
  font-size: 12px;
  color: #999;
  letter-spacing: 0;
}

.form-input input::placeholder,
.form-input textarea::placeholder {
  font-size: 12px;
  color: #999;
  letter-spacing: 0;
}

.form-group .form-input.is-success input:focus,
.form-group .form-input.is-success textarea:focus,
.form-input input:focus,
.form-input textarea:focus {
  background: #fff;
  border-color: #1d1d1d;
}

.form-input textarea {
  height: inherit;
}

.form-input .msg-block {
  display: none;
}

.form-input .icon-input-success {
  display: none;
  position: absolute;
  top: 16px;
  right: 14px;
}

.form-input.is-error input,
.form-input.is-error textarea {
  border-color: #ff220b;
  background: #fff;
}

.form-group.is-error .form-input input:focus,
.form-group.is-error .form-input textarea:focus,
.form-input.is-error input:focus,
.form-input.is-error textarea:focus {
  border-color: #ff220b;
}

.form-input.is-error .msg-block {
  display: block;
  margin-top: 8px;
}

.form-input.is-error .msg-block span {
  color: #ff220b;
}

.form-group.is-disabled input,
.form-group.is-disabled textarea,
.form-input.is-disabled input,
.form-input.is-disabled textarea {
  pointer-events: none;
  cursor: default !important;
  color: #fff !important;
  border-color: #dadada !important;
  background: #dadada !important;
  opacity: 1;
}

.form-group {
  position: relative;
  padding-top: 10px;
}

.form-group .control-label {
  position: absolute;
  padding: 3px 6px 6px;
  display: inline-block;
  z-index: 2;
  background: #fff;
  margin-bottom: 0;
  color: #151515;
  top: -4px;
  left: 10px;
  font-size: 12px;
}

.form-group .form-input.is-success input,
.form-group .form-input.is-success textarea {
  background: #fff;
  border: 1px solid #1d1d1d;
}

.form-group .form-input.is-success .icon-input-success,
.top-nav .top-nav-main .top-nav-right .login-text a span:hover:after {
  display: block;
}

.form-group .form-input.is-focus input,
.form-group .form-input.is-focus textarea {
  background: #fff;
  border-color: #1d1d1d;
}

.form-group.is-error .control-label {
  color: #ff220b !important;
}

.form-group.is-error .form-input input,
.form-group.is-error .form-input textarea {
  border-color: #ff220b !important;
  background: #fff;
}

.form-group.is-error .form-input .msg-block,
.form-group.is-error .msg-block {
  display: block;
  margin-top: 8px;
  padding-left: 16px;
}

.form-group.is-error .form-input .msg-block span,
.form-group.is-error .msg-block span {
  color: #ff220b;
  font-size: 11px;
}

.form-group-btn .form-input {
  width: 320px;
  position: relative;
}

.form-group-btn .form-input .commit-btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  /* color: #1d1d1d; */
  padding: 10px 16px 10px 6px;

  cursor: pointer;
}

.form-group-btn .form-input.is-error .commit-btn,
.form-group-btn.is-error .form-input .commit-btn {
  opacity: 0.4;
}
</style>

<style>
@media (max-width: 1024px),
  screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min--moz-device-pixel-ratio: 2),
  screen and (-o-min-device-piratio: 2),
  screen and (min-device-pixel-ratio: 2) {
}

.icon-wechat,
.icon-weibo {
  background-position: -244px -209px;
  width: 28px;
  height: 23px;
}

.icon-wechat {
  background-position: -289px -225px;
}

.icon-footer-badge1 {
  background-position: -328px -109px;
  width: 14px;
  height: 13px;
  /* vertical-align: -webkit-baseline-middle; */
  vertical-align: middle;
}

.icon-footer-badge2 {
  background-position: -328px -128px;
  width: 13px;
  height: 13px;
  /* vertical-align: -webkit-baseline-middle; */
  vertical-align: middle;
}

.icon-logo {
  background-position: 0 -243px;
  width: 129px;
  height: 9px;
}

.icon-tel {
  background-position: -155px -66px;
  width: 18px;
  height: 18px;
}

.icon-message {
  background-position: -214px -258px;
  width: 18px;
  height: 16px;
}

.icon-store-1 {
  background-position: -328px -24px;
  width: 14px;
  height: 17px;
}

@media (max-width: 1024px) {
  .icon-footer-badge1,
  .icon-footer-badge2 {
    background-position: -328px -204px;
    width: 11px;
    height: 12px;
  }

  .icon-footer-badge2 {
    background-position: -328px -237px;
    margin-top: -4px;
    margin-right: 4px;
  }

  .icon-wechat,
  .icon-weibo {
    background-position: -130px -258px;
    width: 24px;
    height: 20px;
  }

  .icon-wechat {
    background-position: -160px -258px;
  }

  .icon-plus {
    background-position: -328px -166px;
    width: 12px;
    height: 12px;
  }
}
</style>

<style lang="scss">
@media screen and (min-width: 1025px) {
  .ua-login-success-dialog .login-success-container {
    height: auto;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .ua-login-success-dialog .login-success-container p.successNewDialogImg {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .ua-login-success-dialog .login-success-container {
    height: auto;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .ua-login-success-dialog .login-success-container p.successNewDialogImg {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .footer-bottom {
    padding: 4px 0 24px 0 !important;
    border-top: none;
  }

  .telephone-Service {
    div {
      width: 50%;
    }
  }
  .ua-info {
    padding: 0 !important;
    width: 23%;
    min-width: 288px;
  }
}
</style>

<style name="common">
.float-clearfix {
  clear: both !important;
}

.float-clearfix:after {
  display: block;
  content: '';
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
</style>

<style lang="scss">
.footer-search {
  max-width: 1032px;
  padding: 0 20px;
  margin: 0 auto 54px;
}

.footer .footer-main,
.footer-search .container {
  width: 100%;
}

.footer-search .container .tips-note {
  font-size: 14px;
  color: #1d1d1d;
  line-height: 19px;
  margin-bottom: 24px;
}

.footer-search .container .form-footer-search {
  border-bottom: 1px solid #d8d8d8;
  padding: 0 40px 8px 0;
  position: relative;
  margin-bottom: 24px;
}

.footer-search .container .form-footer-search .control-label {
  position: absolute;
  left: 0;
  top: auto;
  bottom: 16px;
  padding: 0;
  font-weight: 700;
  font-size: 32px;
  color: #d0d0d0;
  letter-spacing: -0.5px;
  line-height: 40px;
}

.footer-search .container .form-footer-search .form-input {
  height: 42px;
}

.footer-search .container .form-footer-search .form-input input {
  width: 100%;
  border: 0;
  height: 42px;
  padding: 0;
  font-size: 32px;
  color: #1d1d1d;
}

.footer-search .container .form-footer-search .footer-search-submit {
  position: absolute;
  right: 0;
  top: 50%;
  padding: 8px;
  margin-top: -20px;
  cursor: pointer;
}

.footer-search .container .footer-search-suggestions {
  font-size: 0;
}

.footer-search .container .footer-search-suggestions li {
  display: inline-block;
  font-size: 12px;
  color: #5f5f5f;
  line-height: 16px;
  padding-right: 2px;
}

.footer-search .container .footer-search-suggestions li:nth-child(2) {
  padding-left: 6px;
}

.footer-search .container .footer-search-suggestions li a {
  display: inline-block;
  color: #5f5f5f;
  position: relative;
  text-decoration: underline;
}

.footer {
  background: var(--main-color-ua-light-grey, #f0f0f0);
  position: relative;
}

.footer .footer-main .logo-ua {
  margin-bottom: 24px;
}

.footer .footer-main .footer-top .footer-inner > div:nth-child(1) {
  width: 37.2%;
}

.footer .footer-main .footer-top .footer-inner > div:nth-child(2),
.footer .footer-main .footer-top .footer-inner > div:nth-child(3),
.footer .footer-main .footer-top .footer-inner > div:nth-child(4),
.footer .footer-main .footer-top .footer-inner > div:nth-child(5) {
  width: 15.7%;
}

.footer-top {
  padding: 60px 4% 120px 4%;
}

.footer-top .footer-inner > div .title {
  color: var(--main-color-ua-dark-grey, #14141e);
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 20px;
}
.footer-top .footer-inner {
  div:first-child .title {
    margin-bottom: 16px;
  }
}
.footer-top .footer-inner > div:nth-child(1) .form-group {
  margin-bottom: 32px;
}

.footer-top .footer-inner > div:nth-child(1) .form-group .control-label {
  color: var(--secondary-color-ua-cool-grey-04, #50505a);
  background: 0 0;
  z-index: 3;
}

.footer-top .footer-inner > div:nth-child(1) .form-group .form-input {
  position: relative;
}

.footer-top .footer-inner > div:nth-child(1) .form-group .form-input:after {
  content: '';
  display: block;
  position: absolute;
  width: 96px;
  height: 10px;
  background: #fff;
  opacity: 1;
  top: 0;
  border-top: 1px solid #f0f0f0;
  left: 10px;
  z-index: 2;
}

.footer-top .footer-inner > div:nth-child(1) .contact-us .title {
  margin-bottom: 16px;
}

.footer-top .footer-inner > div:nth-child(1) .contact-us ul {
  font-size: 0;
}

.footer-top .footer-inner > div:nth-child(1) .contact-us ul li {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1024px) {
  .footer-top .platform-item {
    position: relative;
    &:hover .platform-item__popper {
      display: none !important;
    }
  }
}

.footer-top .platform-item {
  // position: relative;
  &:hover .platform-item__popper {
    display: block;
    position: absolute;
    left: 0;
    top: 75px;
    z-index: 1;
  }
  .external-img {
    width: 24px;
    height: 24px;
    background: var(--main-color-ua-light-grey, #f0f0f0);
  }
}

.footer-top .platform-item__popper {
  display: none;
  width: 252px;
  height: 112px;
  img {
    width: 100%;
  }
}

.footer-top .footer-inner > div:nth-child(1) .contact-us ul li:not(:first-child) {
  margin-left: 32px;
  // position: relative;
}

.footer .footer-main .footer-top .footer-inner > div,
.footer-top .footer-inner > div,
.header-simple .header-container .header-main .header-left {
  float: left;
}

.footer-top .footer-inner > div:nth-child(n + 2) ul li {
  margin-bottom: 16px;
}

.footer-top .footer-inner > div.ua-contact ul li a {
  height: 20px;
  font-size: 14px;
  color: #4f4f4f;
  line-height: 20px;
  position: relative;
  display: flex;
}

.footer-top .footer-inner > div.ua-contact ul li a i {
  position: absolute;
  top: 50%;
  left: 0;
}

.footer-top .footer-inner > div.ua-contact ul li a span {
  display: block;
}

.footer-top .footer-inner > div.ua-contact ul li:nth-child(1) a {
  cursor: default;
}

.footer-top .footer-inner > div.ua-contact ul li:nth-child(1) a i {
  margin-top: -9.5px;
}

.footer-top .footer-inner > div.ua-contact ul li:nth-child(2) a i {
  margin-top: -8px;
}

.footer-top .footer-inner > div.ua-contact ul li:nth-child(3) a i {
  margin-top: -9px;
  left: 2px;
}

.footer-top .footer-inner > div.ua-contact ul li:nth-child(4) a i {
  margin-top: -6px;
}

.footer-top .footer-inner > div.ua-contact ul li:nth-child(5) a i {
  margin-top: -7.5px;
}

.footer-top .footer-inner > div:nth-child(n + 2) ul li:last-child {
  margin-bottom: 0;
}

.footer-top .footer-inner > div:nth-child(n + 2) ul li a {
  font-size: 14px;
  color: var(--secondary-color-ua-cool-grey-04, #50505a);
  line-height: 20px;
  font-weight: 400;
}

.footer-bottom {
  min-height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border-top: 1px solid #ddd;
}

.footer-bottom ul li {
  float: left;
  font-size: 12px;
  color: var(--secondary-color-ua-cool-grey-03, #8c8c96);
  line-height: 18px;
  font-weight: 400;
}

.footer-bottom ul {
  display: inline-block;
}

.footer-bottom span.divider,
.footer-bottom li.divider {
  color: var(--line-color-line-grey-01, #ddd);
  line-height: 16px;
  padding: 0 20px;
}

.footer-bottom ul li > a i {
  vertical-align: middle;
  margin-right: 4px;
  margin-bottom: 3px;
}

.icon-footer-badge1 {
  width: 14px;
  height: 13px;
}
.ua-contact {
  .ua-contact-svg {
    display: inline-flex;
    margin-right: 8px;
    margin-top: 2px;
  }
}
</style>

<style lang="scss" layout="mobile">
.page-layout_mobile {
  .footer-bottom span.divider,
  .footer-bottom li.divider {
    color: var(--line-color-line-grey-01, #ddd);
    line-height: 16px;
    padding: 0 12px;
  }
  .none-sm {
    display: none !important;
    visibility: hidden;
  }

  .footer {
    padding: 32px 20px 36px 20px;
  }

  .footer .footer-main {
    max-width: 100%;
    width: 100%;
  }

  .footer .footer-main .footer-top .footer-inner > div,
  .footer .footer-top .footer-inner > div {
    float: none;
  }

  .footer .footer-main .footer-top .footer-inner > div:nth-child(1),
  .footer .footer-main .footer-top .footer-inner > div:nth-child(2),
  .footer .footer-main .footer-top .footer-inner > div:nth-child(3),
  .footer .footer-main .footer-top .footer-inner > div:nth-child(4),
  .footer .footer-main .footer-top .footer-inner > div:nth-child(5),
  .footer .footer-top .footer-inner > div:nth-child(1) .form-group .form-input input {
    width: 100%;
  }

  .footer .footer-top .footer-inner > div .title {
    color: var(--main-color-ua-dark-grey, #14141e);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0;
  }

  .footer .footer-top .footer-inner > div:nth-child(1) {
    padding: 0 12px;
    margin-bottom: 32px;
  }

  .footer .footer-top .footer-inner > div:nth-child(1) .title {
    margin-bottom: 16px;
  }

  .footer .footer-top .footer-inner > div:nth-child(1) .form-group {
    // margin-bottom: 24px;
  }

  .footer .footer-top .footer-inner > div:nth-child(1) .form-group .form-input {
    width: 100%;
    position: relative;
  }

  .footer .footer-top .footer-inner > div:nth-child(1) .contact-us ul li:nth-child(2) {
    // margin-left: 45px;
  }

  .footer
    .footer-top
    .footer-inner
    > div:nth-child(1)
    .contact-us
    ul
    li:nth-child(2):hover
    .wechat-dialog {
    display: block;
  }

  .footer
    .footer-top
    .footer-inner
    > div:nth-child(1)
    .contact-us
    ul
    li:nth-child(2)
    .wechat-dialog {
    position: fixed;
    top: auto;
    bottom: -150%;
    left: 0;
    width: 100%;
    display: block;
    z-index: 8;
    background: #fff;
    -webkit-transition: 0.4s ease-out;
    -o-transition: 0.4s ease-out;
    -moz-transition: 0.4s ease-out;
    transition: 0.4s ease-out;
  }

  .footer
    .footer-top
    .footer-inner
    > div:nth-child(1)
    .contact-us
    ul
    li:nth-child(2)
    .wechat-dialog.show {
    bottom: 0;
    -webkit-transition: 0.4s ease-out;
    -o-transition: 0.4s ease-out;
    -moz-transition: 0.4s ease-out;
    transition: 0.4s ease-out;
  }

  .footer .footer-top .footer-inner > div:nth-child(2) {
    border-top: 1px solid #d8d8d8;
  }

  .footer .footer-top .footer-inner > div:nth-child(2) ul li {
    margin-bottom: 10px !important;
  }

  .footer .footer-top .footer-inner > div:nth-child(2) ul li a {
    padding-left: 30px;
  }

  .footer .footer-top .footer-inner > div:nth-child(n + 2) {
    border-bottom: 1px solid #d8d8d8;
  }

  .footer .footer-top .footer-inner > div:nth-child(n + 2) .title {
    padding: 14px 0;
    position: relative;
    color: var(--secondary-color-ua-cool-grey-04, #50505a);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
  }

  .footer .footer-top .footer-inner > div:nth-child(n + 2) .title > a {
    position: absolute;
    right: 0;
    padding: 0 2px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 14px;
  }

  .footer .footer-top .footer-inner > div:nth-child(n + 2) .title > a i {
    margin-bottom: 6px;
    vertical-align: middle;
  }

  .footer .footer-top .footer-inner > div:nth-child(n + 2) ul {
    display: none;
    padding-top: 4px;
  }

  .footer .footer-top .footer-inner > div:nth-child(n + 2) ul li {
    margin-bottom: 8px;
    // padding-left: 16px;
  }

  .footer .footer-top .footer-inner > div:nth-child(n + 2) ul li a {
    font-size: 12px;
    color: var(--secondary-color-ua-cool-grey-04, #50505a);
  }
  .footer-top {
    padding: 0 0 16px 0 !important;
  }
  .footer-bottom {
    text-align: center;
    padding-bottom: 32px;
    border-top: none;
    margin: 0;
  }
  .platform-item {
    line-height: 0;
  }
  .footer-bottom ul {
    display: inline-block;
  }

  .footer-bottom ul.is-cms li:nth-child(3) {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }

  .footer-bottom ul li {
    float: none;
    font-size: 10px;
    line-height: 1;
    margin-bottom: 4px;
  }

  .footer-bottom ul li.divider {
    color: var(--line-color-line-grey-01, #ddd);
    padding: 0 16px;
    font-weight: 400;
  }

  .footer-bottom ul li:nth-child(1),
  .footer-bottom ul li:nth-child(2),
  .footer-bottom ul li:nth-child(3),
  .footer-bottom ul li:nth-child(4),
  .footer-bottom ul li:nth-child(5),
  .footer-bottom ul li:nth-child(6) {
    display: inline-block !important;
  }

  .footer-bottom ul li:nth-child(1) {
    width: 49%;
    padding-right: 24px;
    text-align: right;
  }

  .footer-bottom ul li:nth-child(2) {
    width: 49%;
    text-align: left;
  }

  .footer-bottom ul li:nth-child(3) {
    width: 45%;
    padding-right: 24px;
    text-align: right;
  }

  .footer-bottom ul li:nth-child(4) {
    width: 52%;
    text-align: left;
  }

  .footer-bottom ul li:nth-child(5) {
    width: 48%;
    text-align: right;
    padding-right: 12px;
  }

  .footer-bottom ul li:nth-child(6) {
    width: 48%;
    text-align: left;
    padding-left: 12px;
  }

  .footer-bottom ul li > a i {
    margin-right: 4px;
  }

  .ua-contact.block-sm {
    display: flex !important;
    color: var(--secondary-color-ua-cool-grey-04, #50505a);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-transform: uppercase;
    > div {
      flex-grow: 1;
      padding: 18px 0;
      display: flex;
      align-items: center;
      &:first-child {
        // border-right: 1px solid var(--line-color-line-grey-01, #ddd);
      }
      &:nth-child(2) {
        padding-left: 20px;
      }
      i {
        margin-right: 8px;
      }
    }
  }
  .van-icon-close {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 20px;
    right: 20px;
    filter: brightness(0%);
  }
}
</style>
