var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMobile)?_c('div',{staticClass:"block-sm footer-bottom__inner-mobile",class:{
    'add-padding': _vm.isHavePadding
  }},[_c('div',[_c('a',{directives:[{name:"click-track",rawName:"v-click-track:buttonClick",value:({
        module_name: '底部-网站地图',
        button_name: '网站地图'
      }),expression:"{\n        module_name: '底部-网站地图',\n        button_name: '网站地图'\n      }",arg:"buttonClick"}],attrs:{"href":"/help/webside-nav"}},[_vm._v("\n      网站地图\n    ")]),_vm._v(" "),_c('span',{staticClass:"divider"},[_vm._v("|")]),_vm._v(" "),_c('a',{directives:[{name:"click-track",rawName:"v-click-track:buttonClick",value:({
        module_name: '底部-沪ICP备',
        button_name: '沪ICP备'
      }),expression:"{\n        module_name: '底部-沪ICP备',\n        button_name: '沪ICP备'\n      }",arg:"buttonClick"}],attrs:{"href":"http://beian.miit.gov.cn","rel":"nofollow"}},[_vm._v("\n      沪ICP备12034417号-1\n    ")])]),_vm._v(" "),_c('div',[_c('a',{directives:[{name:"click-track",rawName:"v-click-track:buttonClick",value:({
        module_name: '底部-电子营业执照',
        button_name: '电子营业执照'
      }),expression:"{\n        module_name: '底部-电子营业执照',\n        button_name: '电子营业执照'\n      }",arg:"buttonClick"}],attrs:{"href":"https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020160422212721000000695527-SAIC_SHOW_310000-gs20140504000002404443&signData=MEQCIFYiI0szhG7ISW1W3z9DDlUOzeuBpdZ95XKelB3Mp4v/AiBV1u8r2LgU6S7FdGdgohpiSUsNKq4BiB0ONzxLyI13mg==","rel":"nofollow"}},[_c('i',{staticClass:"icon icon-footer-badge1"}),_vm._v("\n      电子营业执照\n    ")]),_vm._v(" "),_c('span',{staticClass:"divider"},[_vm._v("|")]),_vm._v(" "),_c('a',{directives:[{name:"click-track",rawName:"v-click-track:buttonClick",value:({
        module_name: '底部-沪公网安备',
        button_name: '沪公网安备'
      }),expression:"{\n        module_name: '底部-沪公网安备',\n        button_name: '沪公网安备'\n      }",arg:"buttonClick"}],attrs:{"href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102003916","rel":"nofollow"}},[_c('i',{staticClass:"icon icon-footer-badge2"}),_vm._v("\n      沪公网安备 31010102003916号\n    ")])]),_vm._v(" "),_c('div',[_c('a',{directives:[{name:"click-track",rawName:"v-click-track:buttonClick",value:({
        module_name: '底部-隐私政策',
        button_name: '隐私政策'
      }),expression:"{\n        module_name: '底部-隐私政策',\n        button_name: '隐私政策'\n      }",arg:"buttonClick"}],attrs:{"href":"https://privacy.underarmour.com/s/article/Global-Privacy-Policy?language=zh_CN","target":"_blank"}},[_vm._v("\n      隐私政策\n    ")]),_vm._v(" "),_c('span',{staticClass:"divider"},[_vm._v("|")]),_vm._v(" "),_c('a',{directives:[{name:"click-track",rawName:"v-click-track:buttonClick",value:({
        module_name: '底部-使用规则',
        button_name: '使用规则'
      }),expression:"{\n        module_name: '底部-使用规则',\n        button_name: '使用规则'\n      }",arg:"buttonClick"}],attrs:{"href":"/help/terms-of-use"}},[_vm._v("\n      使用规则\n    ")])]),_vm._v(" "),_vm._m(0)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_vm._v("Copyright ©2021 Under Armour, Inc.")])])}]

export { render, staticRenderFns }