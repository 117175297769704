<template>
  <div class="ua-logout-page">
    <section class="ua-logout__loading">
      <div
        v-loading="loading"
        class="loading-img"
        element-loading-spinner="iconfont icon-other-logo1"
      ></div>
    </section>
  </div>
</template>

<script>
import { GtImage } from '@speedshop/template';
import logout from '@/mixins/logout.js';

export default {
  name: 'LogOutPage',
  mixins: [logout],
  data() {
    return {
      loading: true,
    }
  },
  components: {
    GtImage
  }
};
</script>
<style lang="scss">
.ua-logout-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .ua-logout__loading {
    width: 80px;
    height: auto;
  }
}

.page-layout_mobile {
  .ua-logout__loading {
    width: 40px;
    height: auto;
  }
}
</style>
