<template>
  <div>
    <gtLayout
      v-if="isRouterAlive"
      :site-info="siteInfo"
      :nav-header-list="navHeaderList"
      :nav-footer-list="navFooterList"
      :is-mobile="mobile"
      :is-guest="isGuest"
      :is-login="isLogin"
      :cart-num="miniCartNum"
      :account-info="accountInfo"
      :reload="reload"
      :my-nav-list="myNavList"
      :other-menus="[]"
      :switch-lang="[]"
      cur-lang-label=""
      :is-webp="isWebp"
      :oss-resize="ossResize"
      :ad-content="adContent"
    >
      <!-- header 相关 -->
      <!-- <client-only> -->
      <template v-if="!isMobile && !['wxpay', 'paySuccess', 'payAgain'].includes($route.name)">
        <div
          v-if="!isMobile"
          slot="header-top"
          class="sp-header-top"
          :class="{
            'hidden-top': $route.path === '/' ? !isTop : false
          }"
        >
          <div class="sp-header-top__wrapper">
            <marquee class="nav-marquee" scrollamount="4" scrolldelay="100">
              <div class="cms-html-edit" style="font-size: 12px">
                <Advertisement
                  slot="header-bottom"
                  :data="adContent.TopAnnouncementBar"
                ></Advertisement>
                <input name="old-userName" type="text" style="display: none" />
                <input name="old-pwd" type="password" style="display: none" />
              </div>
            </marquee>
            <div class="sp-header-top__right">
              <div class="sp-header-top__right-links">
                <!-- @click="isLogin && !isGuest ? $router.push({ name: 'PersonalCenterIndex' }) : null " -->
                <div class="sp-header-top__right-links-item">
                  <!-- 个人中心 -->
                  <no-ssr>
                    <UaHeaderMy></UaHeaderMy>
                  </no-ssr>
                </div>
              </div>
              <div class="sp-header-top__border"></div>
              <span
                v-click-track:buttonClick="{
                  module_name: '顶部-在线客服',
                  button_name: '在线客服'
                }"
                class="customer-service-btn"
                style="cursor: pointer; height: 12px; line-height: 12px"
                @click="customServerValid(true)"
              >
                在线客服
              </span>
              <div class="sp-header-top__border"></div>
              <span style="cursor: pointer; height: 12px; line-height: 12px" @click="handleBrand">
                品牌故事
              </span>
            </div>
          </div>
        </div>

        <!-- PC  端LOGO -->
        <router-link
          v-if="!isMobile"
          slot="header-left"
          :to="{ name: 'home' }"
          class="nav-header-logo"
        >
          <img
            style="width: 64px; height: 64px"
            alt="logo"
            src="@/assets/image/UA_logo.svg"
            @click="$refs.$search.handleShowInput(false)"
          />
        </router-link>

        <div v-if="!isMobile" slot="header-center" style="margin-right: auto; overflow: hidden">
          <UaNav
            class="ua-nav"
            :data="navHeaderList"
            @openNav="$refs.$search.handleShowInput(false)"
          />
        </div>
        <div slot="header-right" class="header-right">
          <div class="header-wish-cart">
            <searchHeader
              v-if="!isMobile"
              ref="$search"
              class="is-pc gt-header-right__search"
            ></searchHeader>
            <div>
              <img
                v-click-track:buttonClick="{
                  module_name: '顶部-我的收藏',
                  button_name: '我的收藏'
                }"
                class="iconfont icon-heart icon-a-16-1px-like1"
                src="@/assets/ua-icon24/icon-heart.svg"
                alt="icon-search"
                style="filter: brightness(0) invert(1) drop-shadow(0 0 0 #fff); cursor: pointer"
                @click="handleClickWish"
              />
            </div>
            <div
              v-click-track:buttonClick="{
                module_name: '顶部-查看购物袋',
                button_name: '查看购物袋'
              }"
              class="icon-shopping"
              @click="$router.push({ name: 'shopCart' })"
            >
              <img
                src="@/assets/ua-icon24/icon-shopping-bag.svg"
                alt="icon-search"
                style="
                  filter: brightness(0) invert(1) drop-shadow(0 0 0 #fff);
                  width: 16px;
                  height: 16px;
                "
                @mouseover="showMiniCart"
              />
              <span v-if="miniCartNum > 0" class="cart-num">
                {{ miniCartNum > 99 ? '99+' : miniCartNum }}
              </span>
            </div>
          </div>
        </div>
        <!--pc nav-->
        <div slot="header-nav">
          <div></div>
        </div>
      </template>

      <!--mobile-->
      <template v-else-if="isMobile && !['wxpay', 'paySuccess', 'payAgain'].includes($route.name)">
        <mobileHeader
          slot="header"
          :mini-cart-num="miniCartNum"
          :nav-header-list="navHeaderList"
          :nav-brand-series-list="navBrandSeriesList"
          :marquee-data="adContent.TopAnnouncementBar"
          :my-nav-list="myNavList"
        ></mobileHeader>
      </template>

      <template v-else>
        <div slot="header"></div>
      </template>

      <!-- <Advertisement slot="header-bottom" :data="adContent.TopAnnouncementBar"></Advertisement> -->

      <!-- footer 相关 -->
      <div slot="footer" slot-scope="{ data }">
        <Footer
          :nav-footer-list="data"
          :site-info="siteInfo"
          @custom-event="customServerValid(true)"
        ></Footer>
      </div>
      <!-- <div slot="footer-top">
        <PLPSearchFooter
          v-if="
            [
              'plp',
              'plpSearch',
              'shopCart',
              'pdp',
              'LandingPage-all',
              'LandingPage',
              'home'
            ].includes($route.name)
          "
        />
      </div> -->
      <template slot="page-top-ad">
        <topAd
          v-if="adPageTop.length && $route.name !== 'pdp' && $route.name !== 'BlindPackagePDP'"
          :key="$route.path"
          class="count-topAd-class"
          :data="adPageTop"
        ></topAd>
        <section v-else></section>
      </template>

      <!-- </client-only> -->
    </gtLayout>
    <no-ssr>
      <ShippingBagDrawer v-if="!isMobile" ref="miniShopCart" />
    </no-ssr>
    <no-ssr>
      <Login ref="drawerLogin" :from-button="showDrawerLoginButton" :visible.sync="visible"></Login>
    </no-ssr>
    <no-ssr>
      <brandStory ref="brandStoryRef"></brandStory>
    </no-ssr>
  </div>
</template>

<script>
import {
  gtLayout,
  isMobile,
  getBrowserLang,
  GtSwitchLang,
  GtHeaderMy,
  GtNav,
  GtSearch,
  GtButton
} from '@speedshop/template';
import { useHome, useAccount, useAdvertisement } from '@speedshop/sdk-api';
import { mapGetters, mapState } from 'vuex';

import { getSite } from '@/graphql/basic.gql';
import Advertisement from '@/components/advertisement.vue';
import UaNav from '@/components/Common/UaNav/index.vue';
import UaHeaderMy from '@/components/Common/HeaderMy/index.vue';
import searchHeader from '@/components/Common/searchHeader/searchHeader.vue';
import mobileHeader from '@/components/Common/searchHeader/mobileHeader.vue';
import Footer from '@/layouts/footer.vue';
import { GuestLogin } from '@/utils/guestLogin.ts';
import { debounce } from '@/utils/tools.ts';
import PLPSearchFooter from '@/components/PLP/HotWordSearch.vue';
import ShippingBagDrawer from '@/components/ShopCart/shippingBagDrawer.vue';
import Login from '@/components/Login/drawerLogin.vue';
import topAd from '@/components/LandingPage/topAd.vue';
import { getUserInfo, getBrandSeriesNav } from '@/graphql/user.gql';
import brandStory from '@/components/Common/BrandStoryDrawer.vue';
import { queryFilterAdvert } from '@/graphql/nav.gql';

async function getAdvertisement(path, isMobile) {
  const Advertisement = useAdvertisement();
  Advertisement.queryData = async params => {
    const {
      data: { data }
    } = await Advertisement.gqlClient.query(queryFilterAdvert, params);
    return data;
  };
  const adContent = await Advertisement.getData({
    input: {
      pageUrl: path === '/' || !path ? '/page/home' : path,
      deviceType: isMobile ? 'h5' : 'pc'
    }
  });
  const {
    AnnouncementBar = [],
    PLPBanner = [],
    PLPBannerWithPosition,
    PageBanner,
    PageBannerWithPosition
  } = adContent;
  const TopAnnouncementBar = AnnouncementBar.length ? AnnouncementBar[0] : {};
  return {
    TopAnnouncementBar,
    AnnouncementBar,
    PLPBanner,
    PLPBannerWithPosition,
    PageBanner,
    PageBannerWithPosition
  };
}

// 判断有几级
const getLevel = (data, deep = 1) => {
  let flag = false;
  let level = deep;
  if (data && data.children && data.children.length > 0) {
    level++;
    data.children &&
      data.children.forEach(v => {
        if (v.children && v.children.length > 0 && !flag) {
          level++;
          flag = true;
        }
      });
  }
  return level;
};

// 判断三级是否有配置图片
const getThirdChildrenHasIcon = data => {
  if (data.children && data.children.length > 0) {
    return data.children.some(v => {
      return () => {
        if (v.children && v.children.length > 0) {
          return () =>
            v.children.some(t => {
              return t.navigationIcon !== '';
            });
        }
      };
    });
  } else {
    return false;
  }
};

export default {
  name: 'Index',
  components: {
    GtButton,
    Login,
    GtSearch,
    GtHeaderMy,
    gtLayout,
    Advertisement,
    Footer,
    GtSwitchLang,
    GtNav,
    UaNav,
    UaHeaderMy,
    searchHeader,
    PLPSearchFooter,
    ShippingBagDrawer,
    mobileHeader,
    topAd,
    brandStory
  },
  provide() {
    return {
      getPlatformIsMobile: () => this.mobile,
      siteInfo: this.siteInfo,
      isGuest: () => this.guest,
      reload: this.reload,
      cartNum: () => this.cartNum,
      accountInfo: () => this.accountInfo,
      myNavList: () => this.myNavList,
      otherMenus: () => this.otherMenus,
      switchLang: () => this.switchLang,
      // curLangLabel: () => this.curLangLabel,
      isWebp: () => this.isWebp,
      customServerValid: this.customServerValid
    };
  },
  async asyncData({ app, store, route }) {
    const headers = app.context?.req?.headers || {};
    const Home = useHome({
      getPlpBasicUrl: category => {
        const firsChildren = category.length && category[0];
        return firsChildren ? `/list/c${firsChildren.extCode || firsChildren.categoryCode}` : null;
      }
    });
    Home.init = async function () {
      // site 信息初始化

      const { data } = await Home.gqlClient.query(getSite);
      const { navHeaderList } = data;

      const computedHeaderList = navHeaderList.map(v => {
        return {
          ...v,
          level: getLevel(v),
          thirdHasImage: getThirdChildrenHasIcon(v)
        };
      });
      const HeaderList = Home.formatData(computedHeaderList);

      return {
        ...data,
        navHeaderList: HeaderList
      };
    };
    console.log('start siteInt');
    let siteData = {};
    let adContent = {};
    const BrandSeriesList = [];
    try {
      await Promise.all([
        (async function b() {
          console.log('start init');
          siteData = await Home.init();
        })(),
        (async function a($route) {
          const { path } = $route;
          adContent = await getAdvertisement(path, isMobile(headers['user-agent']));
        })(route)
      ]);
    } catch (e) {
      console.log(e, 'error site info');
      siteData = {
        navHeaderList: [],
        navFooterList: [],
        siteInfo: {}
      };
      adContent = {
        PLPBannerWithPosition: { top: [] },
        TopAnnouncementBar: {},
        AnnouncementBar: [],
        PLPBanner: []
      };
    }
    const { navHeaderList, navFooterList, siteInfo = {} } = siteData;
    store.commit('setHeaderList', navHeaderList);
    store.commit('setAdData', adContent);

    return {
      navHeaderList,
      navFooterList,
      navBrandSeriesList: BrandSeriesList,
      siteInfo,
      adContent,
      mobile: isMobile(headers['user-agent'])
    };
  },
  data() {
    return {
      defaultCallback: null,
      isRouterAlive: true,
      guest: true,
      showDrawerLoginButton: '',
      switchLang: {
        // 选择选中默认展示shortLabel
        labelKey: 'shortLabel',
        themeIsBlack: true,
        show: true
      },
      ossResize: url => {
        if (url.includes('?')) {
          return url + ',x-oss-process=image/format,webp';
        } else {
          return url + '?x-oss-process=image/format,webp';
        }
      },
      visible: false,
      ResetPasswordVisible: false,
      showGuest: false,
      isTop: true
    };
  },
  head: {
    title: 'Under Armour|安德玛中国官网',
    meta: [
      {
        hid: 'keywords',
        name: 'keywords',
        content: 'Under Armour|安德玛中国官网,Sports'
      },
      {
        hid: 'description',
        name: 'description',
        content: '美国体育运动装备品牌安德玛(UA)中国官网'
      }
    ]
  },
  computed: {
    ...mapGetters(['isMobile', 'isGuest', 'isLogin']),
    ...mapState(['miniCartNum', 'accountInfo', 'isWebp', 'miniShoppingCart']),
    isCrm() {
      return !!this.accountInfo.protocolFlag;
    },
    myNavList() {
      return [
        {
          name: 'PersonalCenterIndex',
          children: [],
          page: '我的首页',
          justCRMVisible: true
        },
        {
          name: 'Order', // router name
          children: ['orderDetails'],
          page: '我的订单' // 页面 title
        },
        {
          name: 'refundList',
          children: [],
          page: '我的售后' // 页面 title
        },
        {
          name: 'coupons',
          children: [],
          page: '我的优惠券'
        },
        {
          name: 'accountSetting',
          children: [],
          page: '个人信息',
          justCRMVisible: true
        }
      ];
    },
    otherMenus() {
      return [];
    },
    scrollTop() {
      return this.$store.state.pageScrollY;
    },
    adPageTop() {
      const { PageBannerWithPosition = {} } = this.adContent || {};
      return PageBannerWithPosition.top || [];
    }
  },

  watch: {
    scrollTop: {
      handler(val) {
        this.isTop = val === 0;
      },
      immediate: true
    },
    isMobile(val) {
      this.mobile = val;
    },
    async $route(to, from) {
      if (!from) return;
      try {
        const adContent = await getAdvertisement(this.$route.path, this.isMobile);
        this.adContent = { ...adContent };
        this.$store.commit('setAdData', adContent);
      } catch (e) {
        console.log(e);
      }
    },
    miniShoppingCart: {
      handler(val, old) {
        if (val.flag) {
          this.$refs &&
            this.$refs.miniShopCart &&
            this.$refs.miniShopCart.show({ title: val.title });
        }
      },
      deep: true
    }
  },
  mounted() {
    this.init();
    this.$nextTick(async () => {
      window.addEventListener('resize', debounce(this.load));
    });

    this.$eventBus.$on('showMiniCart', val => {
      if (this.isMobile) return;
      this.showMiniCart();
    });

    this.$eventBus.$on('drawerLogin', CB => {
      if (this.isMobile) return;
      this.$eventBus.$emit('LoginShow', { showLogin: true, showGuest: true });
    });

    this.$eventBus.$on('drawLoginSuccessCallback', data => {
      this.defaultCallback && this.defaultCallback();
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.load);
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        setTimeout(() => {
          this.isRouterAlive = true;
          this.load();
        }, 50);
      });
    },
    async load() {
      await this.init();
    },
    async init() {
      this.mobile = this.isMobile;
      await this.guestInit();
      await this.cartInit();
    },
    async guestInit() {
      if (
        (localStorage &&
          JSON.parse(localStorage.getItem('userInfo')) &&
          JSON.parse(localStorage.getItem('userInfo')).isVisitor) ||
        localStorage.getItem('token')
      ) {
        //
      } else {
        GuestLogin && (await GuestLogin(this));
        this.guest = !!localStorage.getItem('guestId');
      }
    },
    async cartInit() {
      const Home = useHome();
      if (this.isLogin) {
        await Promise.all(
          this.isGuest
            ? [
                (async () => {
                  const miniCartNum = await Home.getMiniCartNum();
                  this.$store.commit('setMiniCartNum', miniCartNum);
                })()
              ]
            : [
                (async () => {
                  const miniCartNum = await Home.getMiniCartNum();
                  this.$store.commit('setMiniCartNum', miniCartNum);
                })(),
                (async () => {
                  const Account = useAccount();
                  const {
                    data: { userInfo }
                  } = await Account.gqlClient.query(getUserInfo);

                  const originUserInfo = localStorage.getItem('userInfo');
                  const parseInfo = JSON.parse(originUserInfo);
                  localStorage.setItem('userInfo', JSON.stringify({ ...parseInfo, ...userInfo }));

                  this.$store.commit('SET_USER_INFO', { ...parseInfo, ...userInfo });
                })()
              ]
        );
      }
    },
    showMiniCart() {
      this.$refs && this.$refs.miniShopCart && this.$refs.miniShopCart.show('');
    },
    customServerValid(showGuest) {
      if (!this.isGuest && this.isLogin) {
        this.$customerService.open();
      } else {
        this.defaultCallback = () => {
          this.$customerService.open();
        };
        this.$eventBus.$emit('LoginShow', { showLogin: true, showGuest: true });
      }
    },
    handleClickWish() {
      if (!this.isGuest && this.isCrm) {
        this.$router.push({ name: 'wishlist' });
      } else {
        this.showDrawerLoginButton = '我的收藏';
        this.defaultCallback = () => {
          this.$router.push({ name: 'wishlist' });
        };
        this.$eventBus.$emit('LoginShow', { showLogin: true, showGuest: false });
      }
    },
    handleBrand() {
      this.$refs.brandStoryRef && (this.$refs.brandStoryRef.drawerFlag = true);
    }
  }
};
</script>
<style lang="scss">
.gt-header {
  // position: fixed !important;
}
.page-layout_pc {
  // padding-top: 100px !important;
}
.checkout {
  .page-layout_pc {
    padding-top: 0 !important;
  }
}
// .page-layout_pc:has(.gt-plp-page) {
//   .page-view__child {
//     padding-top: 0 !important;
//   }
// }

@mixin liner {
  @keyframes in {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  @keyframes out {
    from {
      background-color: var(--main-color-ua-dark-grey, #14141e);
      left: 0;
    }

    to {
      left: 100%;
    }
  }

  span {
    display: block;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    overflow-x: hidden;
    padding-bottom: 2px;

    &::after {
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      animation: out 0.5s ease;
      animation-play-state: running;
      animation-iteration-count: 1;
    }

    &:hover {
      &::after {
        background-color: var(--main-color-ua-dark-grey, #14141e);
        animation: in 0.5s ease;
      }
    }

    &.active {
      border-bottom: 1px solid var(--main-color-ua-dark-grey, #14141e);
    }
  }
}
.sp-header-top {
  background-color: var(--header-top-background-color);
  color: var(--header-nav-font-color);
  height: 34px;
  border-bottom: 1px solid #fff;
  transition: height 0.3s;
  opacity: 1;

  &__wrapper {
    max-width: 100%;
    padding: 0 4%;
    // margin: 0 auto;
    display: flex;
  }

  &__login-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 34px;
    height: 34px;
  }
  &__border {
    width: 1px;
    height: 12px;
    margin: 2px 12px 0;
    border-right: 1px solid var(--white-30, rgba(255, 255, 255, 0.3));
  }

  &__right {
    position: relative;
    top: -2px;
    display: flex;
    align-items: center;
    color: var(--main-color-ua-white, #fff);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-left: auto;
    .customer-service-btn {
      white-space: nowrap;
      font-style: normal;
      text-transform: uppercase;
    }
    &-links {
      margin-left: 9px;
      white-space: nowrap;
      color: var(--header-top-link-font-color);
      &-item {
        border-right: none;
        // padding: 0 12px 0 0;
      }
    }
  }

  .sp-header-top__links-item {
    a {
      color: inherit;
    }
  }
}

.gt-header {
  z-index: 102 !important;
  background: var(--header-top-background-color) !important;
  color: var(--secondary-color-ua-cool-grey-03) !important;
  width: 100%;

  i {
    cursor: pointer;
    font-size: 20px;
  }

  i:hover {
    color: var(--header-nav-font-color);
  }
}

.header-wish-cart {
  // gap: 24px;
  display: flex;
  min-width: 348px;
  height: 28px;
  line-height: 28px;
  justify-content: flex-end;
  align-items: center;

  i {
    font-size: 16px;
    color: rgb(113, 113, 113);
  }

  .icon-shopping {
    position: relative;
  }

  .icon-heart {
    width: 16px;
    height: 16px;
    margin: 0 24px;
  }

  .cart-num {
    white-space: nowrap;
    position: absolute;
    top: -6px;
    right: -10px;
    background: #ff2a0e;
    border-radius: 8px;
    min-width: 18px;
    min-height: 16px;
    text-align: center;
    font-size: 12px;
    padding: 0 4px;
    font-weight: 600;
    color: #ffffff;
    line-height: 12px;
  }
}

.page-layout_pc {
  //nav
  .gt-header_container {
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 1);
    height: 64px;
    position: relative;
    padding: 0 4% !important;
    max-width: 100%;

    .gt-nav.ua-nav {
      height: 64px !important;
      line-height: 64px;
      position: inherit;
      color: var(--header-nav-font-color);
      padding: 0;
      .gt-nav__item {
        position: inherit;
        // flex: 1 !important;
        white-space: nowrap;

        > div {
          width: auto;
          text-align: center;
          padding: 0 32px;
        }

        .gt-nav__item-label {
          display: inline-block;
          padding: 0;
          color: var(--main-color-ua-white, #fff);
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
        }

        //.gt-nav__item-label.is-active,
        .gt-nav__item-label:hover {
          border-bottom: 2px solid #fff;
          padding-bottom: 4px;
        }
      }
      .gt-nav__item:first-child {
        .gt-nav__item-content {
          padding-left: 0;
        }
      }

      .gt-nav__sub {
        max-width: unset;
        width: 100% !important;
        top: 100%;
        // padding: 33px 4% 48px;
        padding: 0;
      }

      .gt-nav__sub-label {
        text-align: left;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        padding: 0;

        //grid-gap: 70px;
        .gt-nav__sub-item {
          //flex: 1;
          //min-width: 167px;
          margin-right: 0;
          text-decoration: none;

          * {
            text-decoration: none;
          }

          .has-link,
          .gt-nav__sub-link-label {
            text-decoration: none;
            @include liner;
          }

          .gt-nav__sub-item-label {
            margin-bottom: 24px;
          }

          .gt-nav__sub-link-label {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .gt-nav .gt-nav__item:nth-child(1) .gt-nav__sub {
    //visibility: unset;
  }

  .header-right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    //min-width: 30%;
    justify-content: flex-end;
    max-width: max-content;
    height: 34px;
  }
}
.nav-header-logo {
  height: 64px;
  margin-right: 64px;
}
@media (max-width: 1366px) {
  .gt-nav__item {
    position: inherit;
    // flex: 1 !important;
    white-space: nowrap;

    > div {
      width: 100%;
      text-align: center;
      padding: 0 24px !important;
    }
  }
  .nav-header-logo {
    margin-right: 24px;
  }
}

@media (max-width: 1213px) {
  .header-wish-cart {
    min-width: 226px !important;
  }

  .ua-header-search__wrapper {
    width: 130px !important;
  }

  .ua-header-search.focus {
    flex-grow: 0;
  }

  .ua-header-search.focus .ua-header-search__icon {
    transform: translate(113px, -45%) !important;
  }
}

.gt-nav__sub-link-label {
  span {
    font-size: 12px !important;
  }
}

.gt-nav__sub-label {
  .gt-nav__sub-item:nth-of-type(1) {
    .gt-nav__sub-item-label {
      margin-bottom: 0 !important;
    }

    .gt-nav__sub-link-label {
      span {
        // color: var(--main-color-ua-dark-grey, #14141e) !important;
      }
    }
  }
  .gt-nav__sub-link-item {
    height: 35px;
    .gt-nav__sub-link-label {
      span {
        line-height: 26px;
        height: 26px;
        overflow: hidden;
      }
    }
  }
  .gt-nav__sub-item:nth-of-type(2) {
    .gt-nav__sub-link-label {
      span {
        font-size: 14px !important;
      }
    }
    .gt-nav__sub-link-label:first-child {
      margin-top: 2px;
    }
  }
}
</style>

<style lang="scss">
.page-layout_mobile {
  .gt-header_container {
    .center {
      max-width: 45%;
    }

    .header-wish-cart {
      min-width: auto;

      .cart-num {
        top: -5px;
        right: -10px;
      }
    }
  }
}

.gt-nav-mobile-drawer__outer {
  .gt-header-mybasic__item {
    display: none;
  }
}

.nav-marquee {
  width: 60%;
  height: 34px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.gt-advertisement__content {
  height: 30px !important;
}
.hidden-top {
  height: 0;
  transition: height 0.3s;
  opacity: 0;
}
</style>
