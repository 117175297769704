<template>
  <div class="page-transfer-layout">
    <a ref="link" :href="payUrl"></a>
    <div ref="form" v-html="payForm"></div>
  </div>
</template>
<script>
import { usePayment } from '@speedshop/sdk-api';
export default {
  async asyncData({ route }) {
    const {
      query: {
        payUrl,

        orderCode,

        paymentMethod,
        paymentPlatform,
        paymentType,
        paymentVia
      }
    } = route;
    const paymentData = {
      paymentMethod,
      paymentPlatform,
      paymentType,
      paymentVia
    };

    return {
      payUrl,
      orderCode,
      paymentData
    };
  },
  data() {
    return {
      payForm: ''
    };
  },

  async mounted() {
    const Payment = usePayment({ isGoPayDirectly: false });
    try {
      const res = await Payment.getPaymentForm({
        orderCode: this.orderCode,
        paymentParam: this.paymentData
      });
      this.payForm = res.form;
      this.$nextTick(() => {
        if (this.payUrl) {
          this.$refs.link.click();
        } else {
          const $form = document.forms;
          // document.referrer

          $form && $form.length && $form[0].submit();
          window.addEventListener('pageshow', this.back);
        }
      });
    } catch (e) {
      this.$router.go(-1);
    }
  },
  beforeDestroy() {
    window.removeEventListener('pageshow', this.back);
  },
  methods: {
    back() {
      window.removeEventListener('pageshow', this.back);
      window.location.href = `${window.location.origin}/pay-again/${this.orderCode}`;
    }
  }
};
</script>
<style scoped>
.page-transfer-layout {
  opacity: 0;
}
</style>
