<template>
  <div></div>
</template>
<script>
import Cookies from 'js-cookie';
import { GuestLogin } from '@/utils/guestLogin.ts';
export default {
  data() {
    return {
      payForm: ''
    };
  },
  async mounted() {
    Cookies.remove('token');
    Cookies.remove('AUTH-TOKEN');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('token');
    try {
      await this.guestInit();
    } catch (e) {
      //
    } finally {
      this.$router.push('/');
    }
  },
  methods: {
    async guestInit() {
      if (
        (localStorage &&
          JSON.parse(localStorage.getItem('userInfo')) &&
          JSON.parse(localStorage.getItem('userInfo')).isVisitor) ||
        localStorage.getItem('token')
      ) {
        return;
      }
      GuestLogin && (await GuestLogin(this));
      this.guest = !!localStorage.getItem('guestId');
    }
  }
};
</script>
<style scoped>
.page-transfer-layout {
  opacity: 0;
}
</style>
