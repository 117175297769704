import {
  useHome
} from '@speedshop/sdk-api'
import gql from "graphql-tag";
import {
  queryDataDictionary
} from '@speedshop/sdk-api/src/utils/common.ts';
let isArray = arraylike => arraylike instanceof Array;

export function queryJoin(list, lang='zh-CN') {

  const queries = list
    .map(ddCode => {
      return `
      ${ddCode}: queryDataDictionaryListLang(input: { ddCode: "${ddCode}", lang: "${lang}" } ) {
        value: ddValue
        label: ddText
        ddRemark
      }`;
    })
    .join(",");


  return gql `query queryDataDictionaryListLang {${queries}}`;
}

export function toMapByValue(list, labelKey = "label", valueKey = "value") {
  let map = {};

  list.forEach(item => {
    const value = item[valueKey];
    const label = item[labelKey];

    // 部分字典数据的value是由多个值以逗号连接的
    if (/\,/.test(value)) {
      value.split(",").forEach(val => (map[val] = label));
    } else {
      map[value] = label;
    }
  });

  return map;
}

export default ({
  app,
  store
}, inject) => {
  inject('dd', {
    
    _commit(name, value) {
      return store.commit("UPDATE_DDCODE", {
        name,
        value
      });
    },

    async _requestDD(codes) {
      if (codes.length && codes.length === 1) {
        let res = await queryDataDictionary(
          codes,
          useHome().gqlClient
        );
        return res;
      } else if (codes.length && codes.length > 1) {

      let qRes = queryDataDictionary(codes, useHome().gqlClient)

        let resList = codes.map(async (v,i) => {
          let res = await queryDataDictionary(
            codes[i],
            useHome().gqlClient
          );
          return res;
        });
        return resList;
      }
    },

    _filter(codes = []) {
      let ddState = store.state.ddCodes;
      // 排除掉store中已经存在的字典 避免一次不需要的请求
      return codes.filter(code => !ddState[code] || !ddState[code].length);
    },

    async _query(codes, {
      lang
    }) {
      if (codes.length) {
        try {
          let Rquery = await queryDataDictionary(
            codes,
            useHome().gqlClient
          );
          codes.forEach(async v => {           
            Object.keys(Rquery).forEach(d=>{
              const listResult = Rquery[d] || [];
              this._commit(d, listResult);
            });
            
          });
        } catch (err) {
          console.error(err);
        } 
      }
    },

    // preSet(codes) {
    //   codes.forEach(code => {
    //     this._commit(code, []);
    //     this._commit(`${code}_MAP`, {});
    //   });
    // },

    register(codes, config = {}) {
      let ddCodes = this._filter(isArray(codes) ? codes : [codes]);

      return {
        query: this.query.bind(this, ddCodes, {
          ...config,
          filter: false
        }),
        codes,
        config
      };
    },

    // 低鲁棒性 -> codes需要类型检查
    query(codes, {
      lang,
      filter = true
    } = {}) {
      codes = isArray(codes) ? codes : [codes];

      return this._query(filter ? this._filter(codes) : codes, {
        lang: lang || app.i18n && app.i18n.locale || 'zh-CN'
      });
    },

    // clear 目前有未知的bug 不建议使用
    clear() {
      store.commit("CLEAR_DDCOED");
    }
  })
}