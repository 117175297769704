import Vue from 'vue';
import Router from 'vue-router';
// import bindPhone from './components/Login/bindCrm/bindPhone.vue';
// import bindEmail from './components/Login/bindCrm/bindEmail.vue';
// import OrderCancelPage from './components/OrderDetail/CancelPage.vue';
// import OrderReturnPage from './components/Refund/Return.vue';
// import OrderExchangePage from './components/Refund/Exchange.vue';
// import gtMyAccountSettingLoginInfo from './components/AccountSetting/children/entryLoginInfoMobile.vue';
// import gtMyAccountSettingLoginInfoEmail from './components/AccountSetting/children/entryLoginInfoEmailMobile.vue';
// import gtMyAccountSettingPersonalInfo from './components/AccountSetting/children/entryPersonalInfo.vue';
// import gtMyAccountSettingDestoryAccount from './components/AccountSetting/children/entryDestoryAccount.vue';
import { scrollToTop } from '@/utils/dom';
import gtLogout from '@/pages/Logout/index.vue';
// import gtLogin from '@/pages/Login/index.vue';
// import gtThirdLogin from '@/pages/Login/thirdLogin.vue';
// import gtGuestLogin from '@/pages/Login/guestLogin.vue';
// import gtResetPassword from '@/pages/Login/resetPassword.vue';
// import gtRegister from '@/pages/Login/register.vue';
// import gtLinkAccount from '@/pages/Login/linkAccount.vue';
// import gtAthleticClub from '@/pages/Login/athleticClub.vue';
// import gtCompleteUserInfo from '@/pages/Login/completeUserInfo.vue';
// import gtSubscriptionInformationNotice from '@/pages/Login/subscriptionInformationNotice.vue';

// import gtPLP from '@/pages/plp.vue';
// import gtPLPSearch from '@/pages/plpSearch.vue';
// import Order from '@/pages/order.vue';
// import gtOrderDetails from '@/pages/orderDetails.vue';
// import gtReturnDetails from '@/pages/returnDetails.vue';

// import GuestOrder from '@/pages/order/children/order.vue'
// import PersonalCenterIndex from '@/pages/personalCenter.vue';
// import Account from '@/pages/account.vue';
// import gtMyCoupons from '@/pages/myCoupons.vue';

// import gtMyAccountSetting from '@/pages/accountSetting.vue';

// import gtMyWishList from '@/pages/myWishlist.vue';
// import gtTrackOrder from '@/pages/trackOrder.vue';

// import gtPDP from '@/pages/pdp/Index.vue';

// import store from '@/pages/store.vue';
import Layout from '@/pages/index.vue';
// import gtShopCart from '@/pages/shopCart.vue';
// import Contactus from '@/pages/contactus.vue';
// import Address from '@/pages/address.vue';
// import AddressMgmt from '@/pages/AddressMgmt.vue';
// import Faq from '@/pages/faq.vue';
// import landingPage from '@/pages/landingPage.vue';
// import gtCheckout from '@/pages/Checkout/layout.vue';
// import gtPaymentResult from '@/pages/payCallback.vue';
import HealthCheck from '@/pages/healthCheck.vue';
// import SizeGuide from '@/pages/sizeGuide.vue';
// import MemberRights from '@/pages/memberRights.vue';
// import RefundList from '@/pages/refundList.vue';
// import GiftCardList from "@/pages/giftCardList.vue";
// import wxPay from '@/pages/wxpay/wxpay.vue';
// import orderSubmitSuccess from '@/pages/wxpay/order-submit-success.vue';
// import payAgain from '@/pages/wxpay/pay-again.vue';
// import ShopMap from '@/pages/shopMap.vue';

// Help
// import Help from '@/pages/Help/index.vue';
// import Prepaidcard from '@/pages/Help/prepaidcard.vue';
// import PointRule from '@/pages/Help/PointRule.vue';
// import Size from '@/pages/Help/Size.vue';
// import ContactUs from '@/pages/Help/ContactUs.vue';
// import Exchange from '@/pages/Help/Exchange.vue';
// import GroupPurchase from '@/pages/Help/GroupPurchase.vue';
// import HR from '@/pages/Help/HR.vue';
// import Notice from '@/pages/Help/Notice.vue';
// import PayExp from '@/pages/Help/PayExp.vue';
// import Shipping from '@/pages/Help/Shipping.vue';
// import Sitemap from '@/pages/Help/Sitemap.vue';
// import UseTerms from '@/pages/Help/UseTerms.vue';
// import Questionnaire from '@/pages/Help/Questionnaire.vue';
// import Playground from '@/pages/Help/Playground.vue';
// import AdvInherited from '@/pages/AdvInherited.vue';
// import ComingSoon from '@/pages/commingSoon.vue';
import PayTransfer from '@/pages/Checkout/payTransfer.vue';
import GuestLoginTransfer from '@/pages/Login/guestLoginTransfer.vue';
// Brand
// import AboutUs from '@/pages/Brand/AboutUs.vue';
// import Athletes from '@/pages/Brand/Athletes.vue';

// import FFS from '@/pages/FFS/index';
// import FFS_LOGIN from '@/pages/FFS/login';
// import Home from '@/pages/home.vue';
// import Story from '@/pages/uaStory.vue';

import BlindPackage from '@/pages/blindPackage.vue';
import Laboratory from '@/pages/Laboratory.vue';
Vue.use(Router);

// 为了解决(重复点击了同一个路由) Avoided redundant navigation to current location 这个bug
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

function scrollElementTo(element, targetPosition) {
  const startPosition = element.scrollTop;
  const distance = targetPosition - startPosition;
  const duration = 10; // 滚动时间
  const startTime = performance.now();

  function scrollAnimation(currentTime) {
    const elapsedTime = currentTime - startTime;
    const scrollProgress = Math.min(elapsedTime / duration, 1);
    const scrollDistance = distance * scrollProgress;
    const newPosition = startPosition + scrollDistance;

    element.scrollTop = newPosition;

    if (scrollProgress < 1) {
      requestAnimationFrame(scrollAnimation);
    }
  }

  requestAnimationFrame(scrollAnimation);
}
export function createRouter() {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from) {
      const $el = window;

      if (from.meta.keepAlive) {
        const includeRoute = from.meta.includeRoute || [];
        from.meta.savedPosition = includeRoute.includes(to.name) ? window.scrollY : 0;
      }

      if (to.meta.keepAlive) {
        // pdp 锚点定位排除
        if (to.name === 'pdp' && to.hash) return;

        setTimeout(() => {
          // todo requestAnimationFrame to optimize animation
          $el.scrollTo(0, to.meta.savedPosition);
        }, 100);
      } else if ((!to.hash || !to.meta.keepAlive) && to.meta.keepAlive !== undefined) {
        setTimeout(() => {
          $el.scrollTo(0, 0);
        }, 100);
      } else if (to.meta.footerScroll) {
        $el.scrollTo(0, 0);
      } else {
        scrollToTop();
      }
    },
    routes: [
      {
        path: '/blind-package',
        component: BlindPackage,
        meta: {
          title: '闷包'
        }
      },
      {
        path: '/login/transfer',
        name: 'guest-login-transfer',
        component: GuestLoginTransfer,
        chunkName: 'guest-login-transfer'
      },

      {
        path: '/pay-transfer',
        name: 'pay-transfer',
        component: PayTransfer,
        meta: {
          title: '支付页面'
        },
        chunkName: 'pay-transfer'
      },
      {
        path: '/health-check',
        name: 'health-check',
        component: HealthCheck,
        meta: {
          title: '开发者你好页面'
        }
      },
      {
        path: '/logout',
        name: 'Logout',
        component: gtLogout,
        meta: {
          title: '登出'
        }
      },
      // {
      //   path: '/login',
      //   name: 'login',
      //   component: gtLogin,
      //   children: [
      //     {
      //       path: '/login/bindPhone',
      //       name: 'bindPhone',
      //       component: bindPhone,
      //       meta: {
      //         title: '登录绑定手机号页面'
      //       }
      //     },
      //     {
      //       path: '/login/bindEmail',
      //       name: 'bindEmail',
      //       component: bindEmail,
      //       meta: {
      //         title: '登录绑定邮箱页面'
      //       }
      //     }
      //   ],
      //   meta: {
      //     title: '登录'
      //   }
      // },
      // {
      //   path: '/reset-password',
      //   name: 'resetPassword',
      //   component: gtResetPassword,
      //   meta: {
      //     title: '重置密码'
      //   }
      // },
      // {
      //   path: '/ua-athletic-club',
      //   name: 'AthleticClub',
      //   component: gtAthleticClub,
      //   meta: {
      //     title: '完善俱乐部信息'
      //   }
      // },
      // {
      //   path: '/complete-userinfo',
      //   name: 'CompleteUserInfo',
      //   component: gtCompleteUserInfo,
      //   meta: {
      //     title: '完善用户信息'
      //   }
      // },
      {
        path: '/subscription-notice',
        name: 'SubscriptionInformationNotice',
        // component: gtSubscriptionInformationNotice,
        component: () =>
          import('@/pages/Login/subscriptionInformationNotice.vue').then(m => m.default || m),

        meta: {
          title: '订阅通知'
        }
      },
      {
        path: '/adv-inherited/:sourceCode',
        name: 'AdvInherited',
        // component: AdvInherited,
        component: () => import('@/pages/AdvInherited.vue').then(m => m.default || m),

        props: true,
        meta: {
          title: '承接页'
        }
      },
      {
        path: '/checkout/:id',
        name: 'checkout',
        //  component: gtCheckout,
        component: () => import('@/pages/Checkout/layout.vue').then(m => m.default || m),

        meta: {
          title: '订单结算页面'
        },
        chunkName: 'checkout'
      },
      {
        path: '',
        name: 'layout',
        redirect: '/',
        component: Layout,
        children: [
          {
            path: '/',
            name: 'home',
            //  component: Home,
            component: () => import('@/pages/home.vue').then(m => m.default || m),

            meta: {
              title: '首页'
            },
            chunkName: 'home'
          },
          {
            path: '/laboratory',
            component: Laboratory,
            meta: {
              title: '实验室'
            }
          },
          {
            path: '/p:code(.*).htm',
            name: 'pdp',
            // component: gtPDP,
            component: () => import('@/pages/pdp/Index.vue').then(m => m.default || m),

            meta: {
              title: '商品详情页',
              keepAlive: false,
              keepData: {} // 需要缓存的数据参数
            },
            chunkName: 'pdp'
          },
          {
            path: '/blind-package/m:code(.*).htm',
            name: 'BlindPackagePDP',
            // component: gtPDP,
            component: () => import('@/pages/pdp/BlindPackagePDP.vue').then(m => m.default || m),

            meta: {
              title: '闷包商品详情页',
              keepAlive: false,
              keepData: {} // 需要缓存的数据参数
            },
            chunkName: 'BlindPackagePDP'
          },
          {
            path: '/search',
            name: 'plpSearch',
            // component: gtPLPSearch,
            component: () => import('@/pages/plpSearch.vue').then(m => m.default || m),

            meta: {
              title: '商品搜索页面',
              keepAlive: false,
              keepData: {} // 需要缓存的数据参数
            },
            chunkName: 'plpSearch'
          },
          {
            path: '/list/c:category(.*)',
            name: 'plp',
            // component: gtPLP,
            component: () => import('@/pages/plp.vue').then(m => m.default || m),

            meta: {
              title: '商品列表页',
              includeRoute: ['pdp'],
              keepAlive: false,
              keepData: {} // 需要缓存的数据参数
            },
            chunkName: 'plp'
          },
          {
            path: '/brand/aboutua.htm',
            name: 'AboutUs',
            //  component: AboutUs,
            component: () => import('@/pages/Brand/AboutUs.vue').then(m => m.default || m),

            meta: {
              title: '品牌故事页'
            },
            chunkName: 'AboutUs'
          },
          // {
          //   path: '/brand/athletes.htm',
          //   name: 'Athletes',
          //   component: () => import('@/pages/Brand/Athletes.vue').then(m => m.default || m)
          // },
          /* {
            path: '/third-login',
            name: 'third-login',
            component: gtThirdLogin
          }, */
          /* {
            path: '/link-account',
            name: 'link-account',
            component: gtLinkAccount
          }, */
          /* {
            path: '/guest-login',
            name: 'GuestLogin',
            component: gtGuestLogin
          }, */
          /* {
            path: '/register',
            name: 'register',
            component: gtRegister
          }, */
          {
            path: '/shoppingcart.htm',
            name: 'shopCart',
            //  component: gtShopCart,
            component: () => import('@/pages/shopCart.vue').then(m => m.default || m),

            meta: {
              title: '购物车页面'
            },
            chunkName: 'shopCart'
          },
          // {
          //   path: '/order/track',
          //   name: 'TrackOrder',
          //   component: gtTrackOrder,
          //   meta: {
          //     title: '订单查询页'
          //   },
          //   chunkName: 'TrackOrder'
          // },
          {
            path: '/order',
            name: 'GuestOrder',
            //  component: Order,
            component: () => import('@/pages/order.vue').then(m => m.default || m),

            meta: {
              title: '我的订单',
              keepAlive: true,
              includeRoute: []
            },
            chunkName: 'GuestOrder'
          },
          // 退款
          {
            path: '/refund/apply.htm',
            name: 'OrderCancelPage',
            //  component: OrderCancelPage,
            component: () =>
              import('./components/OrderDetail/CancelPage.vue').then(m => m.default || m),

            meta: {
              title: '售后页面',
              keepAlive: false
            },
            chunkName: 'OrderCancelPage'
          },
          // 退货
          {
            path: '/return/apply.htm',
            name: 'OrderReturnPage',
            // component: OrderCancelPage,
            component: () =>
              import('./components/OrderDetail/CancelPage.vue').then(m => m.default || m),

            meta: {
              title: '售后页面',
              keepAlive: false
            },
            chunkName: 'OrderReturnPage'
          },
          // 换货
          {
            path: '/exchange/apply.htm',
            name: 'OrderExchangePage',
            //  component: OrderCancelPage,
            component: () =>
              import('./components/OrderDetail/CancelPage.vue').then(m => m.default || m),

            meta: {
              title: '售后页面'
            },
            chunkName: 'OrderExchangePage'
          },
          {
            path: '/storelocator/index.htm',
            name: 'store',
            // component: store,
            component: () => import('@/pages/store.vue').then(m => m.default || m),

            meta: {
              title: '线下门店页面'
            },
            chunkName: 'store'
          },
          {
            path: '/shop/shopMap',
            name: 'ShopMap',
            // component: ShopMap,
            component: () => import('@/pages/shopMap.vue').then(m => m.default || m),

            meta: {
              title: '门店详情页面'
            },
            chunkName: 'ShopMap'
          },
          {
            path: '/contact-us',
            name: 'ContactUs',
            // component: Contactus,
            component: () => import('@/pages/Help/ContactUs.vue').then(m => m.default || m),

            meta: {
              title: '联系我们页面'
            },
            chunkName: 'ContactUs'
          },
          {
            path: '/information/:id',
            name: 'Faq',
            // component: Faq,
            component: () => import('@/pages/faq.vue').then(m => m.default || m),

            meta: {
              title: '博客页面'
            },
            chunkName: 'Faq'
          },
          {
            path: '/pay-callback',
            name: 'checkoutResult',
            //  component: gtPaymentResult,
            component: () => import('@/pages/payCallback.vue').then(m => m.default || m),

            meta: {
              title: '支付返回页'
            },
            chunkName: 'checkoutResult'
          },
          {
            path: '/page/*',
            name: 'LandingPage',
            // component: landingPage,
            component: () => import('@/pages/landingPage.vue').then(m => m.default || m),
            meta: {
              title: 'LP 页面'
            },
            chunkName: 'LandingPage'
          },
          {
            path: '/Activity/*',
            name: 'LandingPage',
            // component: landingPage,
            component: () => import('@/pages/landingPage.vue').then(m => m.default || m),
            meta: {
              title: 'LP 页面'
            },
            chunkName: 'LandingPage'
          },
          {
            path: '/levelUpChallenge.htm',
            name: 'levelUpChallenge',
            component: () => import('@/pages/levelUpChallenge.vue').then(m => m.default || m),
            chunkName: 'LevelUpChallenge',
            meta: {
              title: '升级挑战',
              keepAlive: false
            }
          },
          {
            path: '/account/',
            name: 'Account',
            //  component: Account,
            component: () => import('@/pages/account.vue').then(m => m.default || m),
            chunkName: 'Account',
            redirect: '/account',
            meta: {
              keepAlive: false
            },
            children: [
              {
                path: 'index.htm',
                name: 'PersonalCenterIndex',
                //  component: PersonalCenterIndex,
                component: () => import('@/pages/personalCenter.vue').then(m => m.default || m),
                chunkName: 'PersonalCenterIndex',
                meta: {
                  title: '个人中心首页',
                  keepAlive: false
                }
              },
              {
                path: 'accountSetting.htm',
                name: 'accountSetting',
                //  component: gtMyAccountSetting,
                component: () => import('@/pages/accountSetting.vue').then(m => m.default || m),
                chunkName: 'accountSetting',
                meta: {
                  title: '账号设置页面',
                  keepAlive: false
                }
              },

              {
                path: '/accountSetting/loginInfo.htm',
                name: 'accountSettingLoginInfo',
                // component: gtMyAccountSettingLoginInfo,
                component: () =>
                  import('@/components/AccountSetting/children/entryLoginInfoMobile.vue').then(
                    m => m.default || m
                  ),
                chunkName: 'accountSettingLoginInfo',
                meta: {
                  title: '账号设置/登录信息页面',
                  keepAlive: false
                }
              },
              {
                path: '/accountSetting/loginInfo/email.htm',
                name: 'accountSettingLogoutEmail',
                //  component: gtMyAccountSettingLoginInfoEmail,
                component: () =>
                  import('@/components/AccountSetting/children/entryLoginInfoEmailMobile.vue').then(
                    m => m.default || m
                  ),
                chunkName: 'accountSettingLogoutEmail',
                meta: {
                  title: '账号设置/登录信息/邮箱页面',
                  keepAlive: false
                }
              },
              {
                path: '/accountSetting/personalInfo.htm',
                name: 'accountSettingPersonInfo',
                //  component: gtMyAccountSettingPersonalInfo,
                component: () =>
                  import('@/components/AccountSetting/children/entryPersonalInfo.vue').then(
                    m => m.default || m
                  ),
                chunkName: 'accountSettingPersonInfo',
                meta: {
                  title: '账号设置/个人信息页面',
                  keepAlive: false
                }
              },
              {
                path: '/accountSetting/logout.htm',
                name: 'accountSettingLogout',
                //  component: gtMyAccountSettingDestoryAccount,
                component: () =>
                  import('@/components/AccountSetting/children/entryDestoryAccount.vue').then(
                    m => m.default || m
                  ),
                chunkName: 'accountSettingLogout',
                meta: {
                  title: '账号设置/注销账号页面',
                  keepAlive: false
                }
              },
              // {
              //   path: 'addressList2.htm',
              //   name: 'address',
              //   //  component: AddressMgmt,
              //   component: () => import('@/pages/AddressMgmt.vue').then(m => m.default || m),
              //   chunkName: 'address',
              //   meta: {
              //     title: '收货地址页面',
              //     keepAlive: false
              //   }
              // },
              {
                path: 'addressList.htm',
                name: 'address',
                // component: Address,
                component: () => import('@/pages/address.vue').then(m => m.default || m),
                chunkName: 'address',
                meta: {
                  title: '收货地址页面',
                  keepAlive: false
                }
              },
              {
                path: 'orderList.htm',
                name: 'Order',
                //  component: Order,
                component: () => import('@/pages/order.vue').then(m => m.default || m),
                chunkName: 'Order',
                meta: {
                  keepAlive: false,
                  includeRoute: ['orderDetails'],
                  title: '订单列表页面'
                }
              },
              // 游客
              // {
              //   path: '/order/detail/:orderCode',
              //   name: 'GuestOrderDetails',
              //   component: gtOrderDetails,
              //   meta: {
              //     title: '订单详情页面',
              //     keepAlive: false
              //   }
              // },
              // 非游客订单详情
              {
                path: 'order/detail/:orderCode',
                name: 'orderDetails',
                // component: gtOrderDetails,
                component: () => import('@/pages/orderDetails.vue').then(m => m.default || m),
                chunkName: 'orderDetails',
                meta: {
                  title: '订单详情页面',
                  includeRoute: ['ReturnDetails', 'OrderCancelPage'],
                  keepAlive: false
                }
              },
              {
                path: '/refund/list.htm',
                name: 'refundList',
                // component: RefundList,
                component: () => import('@/pages/refundList.vue').then(m => m.default || m),
                chunkName: 'refundList',
                meta: {
                  keepAlive: false,
                  includeRoute: ['ReturnDetails'],
                  title: '售后列表页面'
                }
              },
              {
                path: '/refundApplyDetail/:returnCode',
                name: 'ReturnDetails',
                // component: gtReturnDetails,
                component: () => import('@/pages/returnDetails.vue').then(m => m.default || m),
                chunkName: 'ReturnDetails',
                meta: {
                  title: '售后详情页面',
                  keepAlive: false
                }
              },
              {
                path: 'couponList.htm',
                name: 'coupons',
                // component: gtMyCoupons,
                component: () => import('@/pages/myCoupons.vue').then(m => m.default || m),
                chunkName: 'coupons',
                meta: {
                  keepAlive: true,
                  includeRoute: [],
                  title: '我的优惠券页面'
                }
              },
              {
                path: 'favoriteList.htm',
                name: 'wishlist',
                // component: gtMyWishList,
                component: () => import('@/pages/myWishlist.vue').then(m => m.default || m),
                chunkName: 'wishlist',
                meta: {
                  keepAlive: true,
                  includeRoute: [],
                  title: '我的喜欢页面'
                }
              },
              {
                path: 'memberRight.htm',
                name: 'memberRights',
                // component: MemberRights,
                component: () => import('@/pages/memberRights.vue').then(m => m.default || m),
                chunkName: 'memberRights',
                meta: {
                  title: '会员权益页面'
                }
              }
              // {
              //   path: "giftCardList",
              //   name: "giftCardList",
              //   component: GiftCardList,
              // },
            ]
          },
          {
            path: '/size-guide',
            name: 'SizeGuide',
            // component: SizeGuide,
            component: () => import('@/pages/sizeGuide.vue').then(m => m.default || m),
            chunkName: 'SizeGuide',
            meta: {
              title: '开发案例页面'
            }
          },
          {
            path: '/help',
            name: 'help',
            //  component: Help,
            component: () => import('@/pages/Help/index.vue').then(m => m.default || m),

            meta: {
              title: '底部帮助',
              keepAlive: true
            },
            chunkName: 'Help',
            children: [
              {
                path: 'prepaidcard',
                name: 'prepaidcard',
                //  component: Prepaidcard,
                component: () => import('@/pages/Help/prepaidcard.vue').then(m => m.default || m),

                meta: {
                  title: '礼品卡使用章程页面',
                  footerScroll: true
                },
                chunkName: 'prepaidcard'
              },
              {
                path: 'point-rule.htm',
                name: 'point-rule',
                //  component: PointRule,
                component: () => import('@/pages/Help/PointRule.vue').then(m => m.default || m),

                meta: {
                  title: '会员政策页面',
                  footerScroll: true
                },
                chunkName: 'point-rule'
              },
              {
                path: 'size.htm',
                name: 'help-size',
                // component: Size,
                component: () => import('@/pages/Help/Size.vue').then(m => m.default || m),

                meta: {
                  title: '尺码裁剪页面',
                  footerScroll: true
                },
                chunkName: 'help-size'
              },
              {
                path: 'contact-us',
                name: 'ContactUs2',
                component: () => import('@/pages/Help/ContactUs.vue').then(m => m.default || m),
                meta: {
                  title: '联系我们页面',
                  footerScroll: true
                },
                chunkName: 'ContactUs2'
              },
              {
                path: 'exchange',
                name: 'Exchange',
                component: () => import('@/pages/Help/Exchange.vue').then(m => m.default || m),
                meta: {
                  title: '退还政策说明页',
                  footerScroll: true
                },
                chunkName: 'Exchange'
              },
              {
                path: 'group-purchase',
                name: 'GroupPurchase',
                component: () => import('@/pages/Help/GroupPurchase.vue').then(m => m.default || m),
                meta: {
                  title: '大客户团购页面',
                  footerScroll: true
                },
                chunkName: 'GroupPurchase'
              },
              {
                path: 'hr',
                name: 'HR',
                // component: HR,
                component: () => import('@/pages/Help/HR.vue').then(m => m.default || m),

                meta: {
                  title: '加入我们页面',
                  footerScroll: true
                },
                chunkName: 'HR'
              },
              {
                path: 'notice',
                name: 'Notice',
                // component: Notice,
                component: () => import('@/pages/Help/Notice.vue').then(m => m.default || m),

                meta: {
                  title: '公告页面',
                  footerScroll: true
                },
                chunkName: 'Notice'
              },
              {
                path: 'pay-exp',
                name: 'PayExp',
                //  component: PayExp,
                component: () => import('@/pages/Help/PayExp.vue').then(m => m.default || m),

                meta: {
                  title: '支付说明页',
                  footerScroll: true
                },
                chunkName: 'PayExp'
              },
              {
                path: 'shipping',
                name: 'Shipping',
                //  component: Shipping,
                component: () => import('@/pages/Help/Shipping.vue').then(m => m.default || m),

                meta: {
                  title: '物流与配送页面',
                  footerScroll: true
                },
                chunkName: 'Shipping'
              },
              {
                path: 'webside-nav',
                name: 'Sitemap',
                //  component: Sitemap,
                component: () => import('@/pages/Help/Sitemap.vue').then(m => m.default || m),

                meta: {
                  title: '网站地图页面',
                  footerScroll: true
                },
                chunkName: 'Sitemap'
              },
              {
                path: 'terms-of-use',
                name: 'TermsOfUse',
                //  component: UseTerms,
                component: () => import('@/pages/Help/UseTerms.vue').then(m => m.default || m),

                meta: {
                  title: '使用规则页面',
                  footerScroll: true
                },
                chunkName: 'TermsOfUse'
              },
              {
                path: 'privacy',
                name: 'Privacy',
                //  component: UseTerms,
                component: () => import('@/pages/Help/UseTerms.vue').then(m => m.default || m),

                meta: {
                  title: '隐私政策页面',
                  footerScroll: true
                },
                chunkName: 'Privacy'
              },
              {
                path: 'survey',
                name: 'Survey',
                // component: Questionnaire,
                component: () => import('@/pages/Help/Questionnaire.vue').then(m => m.default || m),

                meta: {
                  title: '反馈页面',
                  footerScroll: true
                },
                chunkName: 'Survey'
              },
              {
                path: 'mini-survey',
                name: 'MiniSurvey',
                // component: Questionnaire,
                component: () => import('@/pages/Help/Questionnaire.vue').then(m => m.default || m),
                meta: {
                  title: '迷你反馈页面',
                  footerScroll: true
                },
                chunkName: 'MiniSurvey'
              },
              {
                path: 'questionnaire',
                name: 'Questionnaire',
                // component: Questionnaire,
                component: () => import('@/pages/Help/Questionnaire.vue').then(m => m.default || m),
                meta: {
                  title: '反馈问题页面',
                  footerScroll: true
                },
                chunkName: 'Questionnaire'
              },
              {
                path: 'playground',
                name: 'Playground',
                // component: Playground,
                component: () => import('@/pages/Help/Playground.vue').then(m => m.default || m),
                meta: {
                  title: '视频示例页面',
                  footerScroll: true
                },
                chunkName: 'Playground'
              }
            ]
          },
          {
            path: '/pay/:orderCode',
            name: 'wxpay',
            //  component: wxPay,
            component: () => import('@/pages/wxpay/wxpay.vue').then(m => m.default || m),

            meta: {
              title: '微信支付页'
            },
            chunkName: 'wxpay'
          },
          {
            path: '/pay-again/:orderCode',
            name: 'payAgain',
            //  component: payAgain,
            component: () => import('@/pages/wxpay/pay-again.vue').then(m => m.default || m),

            meta: {
              title: '支付宝支付页'
            },
            chunkName: 'payAgain'
          },
          {
            path: '/paySuccess',
            name: 'paySuccess',
            //  component: wxPay,
            component: () => import('@/pages/wxpay/wxpay.vue').then(m => m.default || m),
            meta: {
              title: '支付成功页面'
            },
            chunkName: 'paySuccess'
          },
          {
            path: '/major-pay/:orderCode',
            name: 'orderSubmitSuccess',
            //  component: orderSubmitSuccess,
            component: () =>
              import('@/pages/wxpay/order-submit-success.vue').then(m => m.default || m),

            meta: {
              title: '订单提交成功页面'
            },
            chunkName: 'orderSubmitSuccess'
          },
          {
            path: '/coming-soon',
            name: 'ComingSoon',
            // component: ComingSoon,
            component: () => import('@/pages/commingSoon.vue').then(m => m.default || m),

            meta: {
              title: '网站维护公告页面'
            },
            chunkName: 'ComingSoon'
          },
          {
            path: '/ffs/invite/:code',
            name: 'FFS',
            //  component: FFS,
            component: () => import('@/pages/FFS/index').then(m => m.default || m),

            meta: {
              title: '内购登录'
            },
            chunkName: 'FFS'
          },
          {
            path: '/ffs/login/:code',
            name: 'FFSLogin',
            // component: FFS_LOGIN,
            component: () => import('@/pages/FFS/login').then(m => m.default || m),
            meta: {
              title: '内购登录'
            },
            chunkName: 'FFSLogin'
          },
          {
            path: '/story',
            name: 'uaStory',
            component: () => import('@/pages/uaStory.vue').then(m => m.default || m),
            // component: Story,
            meta: {
              title: 'UA 故事'
            },
            chunkName: 'uaStory'
          },
          {
            path: '/login',
            name: 'Login',
            //  component: landingPage,
            component: () => import('@/pages/landingPage.vue').then(m => m.default || m),

            meta: {
              title: 'Login'
            },
            chunkName: 'Login'
          },
          {
            path: '/*',
            name: 'LandingPage-all',
            //  component: landingPage,
            component: () => import('@/pages/landingPage.vue').then(m => m.default || m),

            meta: {
              title: 'LP 页面'
            },
            chunkName: 'LandingPage-all'
          }
        ]
      }
    ]
  });
}
