/**
 * 文档Y轴的滚动距离
 */
export function getScrollTop() {
  return document.documentElement.scrollTop || document.body.scrollTop;
}

/**
 * 文档滚动到顶部
 */
export function scrollToTop(toPosition: number = 0): void {
  document.body.scrollTop = toPosition; // For Safari
  document.documentElement.scrollTop = toPosition; // For Chrome, Firefox, IE and Opera
}
/**
 * 文档滚动到顶部
 */
export function scrollToTopW(toPosition: number = 0): void {
  if ('scrollBehavior' in document.documentElement.style) {
    // 浏览器支持平滑滚动
    window.scrollTo({
      top: toPosition,
      behavior: 'smooth'
    });
  } else {
    // 浏览器不支持平滑滚动，可以使用传统的瞬间滚动
    // window.scrollTo(0, 0);
    document.body.scrollTop = toPosition; // For Safari
    document.documentElement.scrollTop = toPosition; // For Chrome, Firefox, IE and Opera
  }
}

/**
 * 检查目标元素是否在视口
 * @returns boolean
 */
export function isElementVisible(el) {
  const rect = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement?.clientHeight;

  if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) {
    return false;
  }

  return true;
}
