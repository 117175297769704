<template>
  <div
    v-if="isMobile"
    class="block-sm footer-bottom__inner-mobile"
    :class="{
      'add-padding': isHavePadding
    }"
  >
    <div>
      <a
        v-click-track:buttonClick="{
          module_name: '底部-网站地图',
          button_name: '网站地图'
        }"
        href="/help/webside-nav"
      >
        网站地图
      </a>
      <span class="divider">|</span>
      <a
        v-click-track:buttonClick="{
          module_name: '底部-沪ICP备',
          button_name: '沪ICP备'
        }"
        href="http://beian.miit.gov.cn"
        rel="nofollow"
      >
        沪ICP备12034417号-1
      </a>
    </div>

    <div>
      <a
        v-click-track:buttonClick="{
          module_name: '底部-电子营业执照',
          button_name: '电子营业执照'
        }"
        href="https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020160422212721000000695527-SAIC_SHOW_310000-gs20140504000002404443&signData=MEQCIFYiI0szhG7ISW1W3z9DDlUOzeuBpdZ95XKelB3Mp4v/AiBV1u8r2LgU6S7FdGdgohpiSUsNKq4BiB0ONzxLyI13mg=="
        rel="nofollow"
      >
        <i class="icon icon-footer-badge1"></i>
        电子营业执照
      </a>
      <span class="divider">|</span>
      <a
        v-click-track:buttonClick="{
          module_name: '底部-沪公网安备',
          button_name: '沪公网安备'
        }"
        href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102003916"
        rel="nofollow"
      >
        <i class="icon icon-footer-badge2"></i>
        沪公网安备 31010102003916号
      </a>
    </div>
    <div>
      <a
        v-click-track:buttonClick="{
          module_name: '底部-隐私政策',
          button_name: '隐私政策'
        }"
        href="https://privacy.underarmour.com/s/article/Global-Privacy-Policy?language=zh_CN"
        target="_blank"
      >
        隐私政策
      </a>
      <span class="divider">|</span>
      <a
        v-click-track:buttonClick="{
          module_name: '底部-使用规则',
          button_name: '使用规则'
        }"
        href="/help/terms-of-use"
      >
        使用规则
      </a>
    </div>
    <div>
      <span>Copyright ©2021 Under Armour, Inc.</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isHavePadding: false
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'isGuest', 'isLogin'])
  },
  watch: {
    $route(val, oldVal) {
      this.handleIsHavePadding();
    }
  },
  methods: {
    handleIsHavePadding() {
      let classDom = null;
      if (process.client) {
        // 在客户端环境下执行以下代码
        classDom = document.querySelector('.pdp') || document.querySelector('.shopCart');
        this.isHavePadding = !!classDom;
      }
    }
  }
};
</script>

<style lang="scss">
.icon {
  background-size: 342px 338px;
  display: inline-block;
}
@media (max-width: 1024px) {
  .icon-footer-badge1,
  .icon-footer-badge2 {
    background-position: -328px -204px;
    width: 11px;
    height: 12px;
  }
  .icon-footer-badge2 {
    background-position: -328px -237px;
    margin-top: -4px;
    margin-right: 4px;
  }
}
.footer-bottom__inner-mobile {
  color: var(--secondary-color-ua-cool-grey-03, #8c8c96);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  > div:not(:last-child) {
    margin-bottom: 8px;
  }
  > div:last-child {
    // margin-top: 16px;
  }
}
.footer-bottom__inner-mobile {
  color: var(--secondary-color-ua-cool-grey-03, #8c8c96);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  > div:not(:last-child) {
    margin-bottom: 12px;
  }
  > div:last-child {
    margin-top: 17px;
  }
}
.add-padding {
  padding-bottom: 60px;
}
</style>
