import cookie from 'js-cookie';
import { getStorage, setStorage } from './utils';

export interface ObserveOption {
  duration: number;
  resetHandle: () => any;
}

interface ObserveItem {
  data?: any;
  expired?: number;
  expiredString?: string;
}

const KEY_USER_ACTIVE_OBSERVES = 'TrackHelp/user-active-listener';
let observeOption: Record<string, ObserveOption> | undefined;
const observes: Record<string, ObserveItem> = {};
let timer: number | null;
let inactiveHandle: (k: string, d: any) => void | undefined;

const checkObserves = () => {
  for (const key in observeOption) {
    const item = observes[key];
    const option = observeOption[key];
    // 如果超时，则更新数据
    if (item.expired < new Date().valueOf()) {
      console.log('user-active-listener: expired', key, item, option);
      setData(key, option.resetHandle?.());
    }
  }
};

const initTimer = () => {
  const createTimeout = () => {
    // console.log('user-active-listener: heat beat');
    checkObserves();
    timer = window.setTimeout(createTimeout, 1000);
  };
  createTimeout();
};
const removeTime = () => {
  if (timer) {
    clearTimeout(timer);
  }
};

const initExpired = () => {
  for (const key in observeOption) {
    setExpired(key);
  }
};

const setExpired = key => {
  const option = observeOption[key];
  const expired = new Date().valueOf() + (option?.duration || 0);
  observes[key].expired = expired;
  observes[key].expiredString = new Date(expired).toLocaleString();
};

const cacheObserves = () => {
  for (const key in observeOption) {
    const { data, expired } = observes[key];
    setStorage(
      `${KEY_USER_ACTIVE_OBSERVES}/${key}`,
      data,
      new Date(expired).valueOf() - new Date().valueOf()
    );
    const cookieData = JSON.parse(cookie.get('sensors_publick_properties') || '{}');
    const payload = {
      ...cookieData,
      [key]: data
    };
    // 从公共属性中去除动态属性，不需要存储，以实际上报为准
    delete payload.module_name;
    delete payload.module_rank;
    delete payload.operation_id;
    delete payload.operation_name;
    delete payload.operation_rank;
    cookie.set('sensors_publick_properties', JSON.stringify(payload), {
      path: '/',
      ...(process.env.NODE_ENV === 'production'
        ? {
            sameSite: 'None',
            domain: '.' + location.host.split('.').slice(-2).join('.'),
            secure: true
          }
        : {})
    });
  }
};
const commonHandle = () => {
  // console.log('user-active-listener: eventListener');
  initExpired();
};
const visiblechangeHandle = () => {
  if (document.visibilityState === 'visible') {
    weekup();
  } else {
    sleep();
  }
};

const removeListener = () => {
  document.removeEventListener('mousemove', commonHandle);
  document.removeEventListener('keydown', commonHandle);
  document.removeEventListener('touchstart', commonHandle);
  document.removeEventListener('visibilitychange', visiblechangeHandle);
};

const initListener = () => {
  document.addEventListener('mousemove', commonHandle);
  document.addEventListener('keydown', commonHandle);
  document.addEventListener('touchstart', commonHandle);
  document.addEventListener('visibilitychange', visiblechangeHandle);
  document.addEventListener('unload', () => {
    // console.log('user-active-listener: unload');
    removeListener();
  });
};

const setData = (key: string, data: any) => {
  // console.log('user-active-listener: setData', JSON.stringify(observes), key, data);
  if (!observes[key]) {
    observes[key] = {};
  }
  observes[key].data = data;
  setExpired(key);
  inactiveHandle?.(key, data);
};

const updateData = (key: string, updateHandle: (d: any) => any) => {
  console.log('user-active-listener: updateData', key, updateHandle(observes[key].data));
  setData(key, updateHandle(observes[key].data));
};

const sleep = () => {
  removeTime();
  cacheObserves();
};

const weekup = () => {
  for (const key in observeOption) {
    const option = observeOption[key];
    const session = getStorage(`${KEY_USER_ACTIVE_OBSERVES}/${key}`);
    setData(key, session || option.resetHandle?.());
  }
  initTimer();
};

export default (options: {
  observes: Record<string, ObserveOption>;
  inactiveHandle: (k: string, d: any) => void;
}) => {
  console.log('user-active-listener: init');
  inactiveHandle = options.inactiveHandle;
  observeOption = options.observes;
  weekup();
  initListener();

  return {
    updateData
  };
};
